import React, {Component} from 'react'
import {Helmet} from 'react-helmet';

class ChangeTitle extends Component{
    constructor(props){
        super(props)
    }


    render(){
        return(
            <div>
                <div className="application">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{this.props.title}</title>
                        <link rel="canonical" href="http://mysite.com/example" />
                    </Helmet>
                </div>
            </div>
        )
    }
}

export default ChangeTitle




















