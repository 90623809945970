import React, {Component} from 'react';
import Loadable from 'react-loadable';
import {Route, Redirect} from "react-router-dom";
import Loading from '../loading'

class Receive extends Component {
    render() {
        return (
            <div>
                <Route exact path={this.props.match.url} render={() => (
                    <Redirect to={this.props.match.url + "/fillinfo"}/>
                )}/>
                <Route path={this.props.match.url + "/fillinfo"} component={
                    Loadable({
                        loader: () => import('./receive_fill'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/link"} component={
                    Loadable({
                        loader: () => import('./receive_link'),
                        loading: Loading
                    })
                }/>
            </div>
        );
    }
}

export default Receive;
