import React, {Component} from 'react'
import {Button, Modal} from 'antd-mobile';
// import '.../../distribution/style/index.less'
import Api from "../../../util/Api";
// import PosterImg from "../../../image/huge.jpg"
// import PosterImg from "../../../image/yishan.jpg"
import {Toast} from "antd-mobile/lib/index";
import {Path} from "../../../path";
import Util from "../../../util/util";

class SharePic extends Component {
    constructor(props) {
        super(props);
        this.closePoster = this.closePoster.bind(this);
        this.closeGzhh = this.closeGzhh.bind(this);
        this.getGzhhh = this.getGzhhh.bind(this);
        this.getNum = this.getNum.bind(this);
        this.showGzhh = this.showGzhh.bind(this);
        this.showHelpShare = this.showHelpShare.bind(this);
        this.state = {
            showGzhh: false,
            gzhhShow: false,
            royalty: props.royalty || window.sessionStorage.getItem("royalty") || 0,
            imgNum: 0,
            shareImg: "",
            imgScale: 0.5,
            showHelpShare:false,
            showToolShare:false,
        }
    }

    componentDidMount() {
        if (this.props.showHelpShare == true) {
            this.showHelpShare()
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.showHelpShare == true) {
            this.showHelpShare()
            if (nextProps.royalty) {
                this.setState({
                    royalty: nextProps.royalty
                })
            } else if (window.sessionStorage.getItem("royalty")) {
                this.setState({
                    royalty: window.sessionStorage.getItem("royalty")
                })
            }
        }

    }

    getGzhhh() {
        Api.getQrcodeData((data) => {
            if (data.respCode == 0) {
                this.setState({
                    gzhImg: data.respMsg.qrcode,
                    showGzhh: true
                })
            }
        }, () => {
            Toast.info("公众号系统错误", 3, null, false)
        })
    }

    showHelpShare() {
        Api.getPosterData((data) => {
            if (data.respCode == 0) {
                this.getNum(data.respMsg.url)
                this.setState({
                    shareImg: data.respMsg.url,
                    showHelpShare: true,
                    // gzhhShow:data.respMsg.subscribe
                })
            } else if (data.respCode == 3001) {
                this.setState({
                    showHelpShare: false,
                    showToolShare: true
                })
            }
        }, () => {
            Toast.info("海报系统错误", 3, null, false)
        })

    }


    closePoster() {
        this.setState({
            showGzhh: false,
            showHelpShare: false,
        })
        this.props.closePoster(false)
    }

    closeGzhh() {
        this.setState({
            showGzhh: false,
            showHelpShare: false,
        })
    }

    showGzhh() {
        this.setState({
            showGzhh: true
        })
        this.getGzhhh()
    }

    getNum(url) {
        let imageReader = new Image(), num = 0, that = this;
        imageReader.src = url;
        // imageReader.src = PosterImg;
        imageReader.onload = function () {
            let width = imageReader.width;
            let height = imageReader.height;
            num = width / height
            that.setState({
                imgScale: num
            })
            // console.log(num)
            // return num
        };
    }

    noPayClose(e){
        e.stopPropagation()
        this.setState({
            showToolShare:false
        })
        this.props.closePoster(false)
    }

    gotoPayNext(goodsData){
        if(goodsData.needStock == 1 && goodsData.stock == 0){
            Modal.alert('提示', Util.checkCode[2001], [
                {
                    text: '取消',
                    onPress: () => console.log('cancel'),
                    style: 'cancel',
                }
            ]);
        }else{
            window.location.href=Path.getOpenPay()
        }
    }

    render() {

        return (
            <div>
                {
                    this.state.showHelpShare ?
                        <div>
                            <div className={'helpPoster'}>
                                <div
                                    className={"clearfix distriPoster helpPosterTop " + (!this.props.gzhhShow ? "mxd-display-block" : "mxd-display-none")}>
                                    <div className={'mxd-float-left distriPosterWord'}>
                                        {/*<i className={'icon-gzhh-tooltip'}></i>*/}
                                        <span className={'helpPosterTopWord'}>关注公众号，<span
                                            className={'mxd-display-block'}>及时接收奖励金到账通知</span></span>
                                    </div>
                                    <div className={'mxd-float-right'}>
                                        <Button type='warning' className={'helpPosterBtn'}
                                                onClick={this.showGzhh}>马上关注</Button>
                                    </div>
                                </div>
                                <div className={'helpPosterImgBg'} onClick={this.closePoster}>
                                    {/*<img src={this.state.shareImg?this.state.shareImg:""} className={'helpPosterImg'}*/}
                                    {/*style={{height:this.state.imgScale>=1?"auto":"calc(100vh - 1.8rem)",width:this.state.scaleFlag==true?"calc(100vw - 0.9rem)":"auto",*/}
                                    {/*top:this.state.scaleFlag==true?"50%":"0.8rem",left:this.state.scaleFlag==true?"50%":"50%",transform:this.state.scaleFlag==true?"translate(-50%,-50%)":"translate(-50%,0)"}}/>*/}
                                    <img src={this.state.shareImg ? this.state.shareImg : ""}
                                         className={'helpPosterImg'}
                                         style={{
                                             height: this.state.imgScale >= 1 ? "auto" : "calc(100vh - 1.8rem)",
                                             width: this.state.imgScale >= 1 ? "calc(100vw - 0.9rem)" : "auto",
                                             top: this.state.imgScale >= 1 ? "50%" : "0.8rem",
                                             left: "50%",
                                             transform: this.state.imgScale >= 1 ? "translate(-50%,-50%)" : "translate(-50%,0)"
                                         }}/>
                                </div>

                                {/*<img src={PosterImg} className={'helpPosterImg'} style={{height:this.getNum()>=1?"100%":"calc(100vh - 1.35rem)",*/}
                                {/*width:this.getNum()>=1?"calc(100vw - 0.5rem)":"100%", top:this.getNum()>=1?"50%":"0.45rem",*/}
                                {/*left:this.getNum()>=1?"0":"50%",transform:this.getNum()>=1?"translate(0,-50%)":"translate(-50%,0)"}}/>*/}
                                <div className={'helpPosterBottom'}>
                                    <span className={'mxd-display-block'}>长按保存图片，发给好友或分享朋友圈</span>
                                    <span>每邀请一位好友购买，您立即获得<span
                                        className={'helpPosterTopRed'}>{this.state.royalty ? this.state.royalty : 0}</span>元奖励</span>
                                </div>
                                {/*{*/}
                                {/*this.state.showGzhh?<div className={'am-modal am-modal-transparent showGzhhCon'}>*/}
                                {/*<div className={'am-modal-content'}>*/}
                                {/*<div className={'am-modal-body'}>*/}
                                {/*<div className={'gzhhCon'}>*/}
                                {/*<img src={(this.state.gzhImg?this.state.gzhImg:"")} className={'gzhhImg'}/>*/}
                                {/*/!*<img src={PosterImg} className={'gzhhImg'}/>*!/*/}
                                {/*<div className={'gzhhWord'}>*/}
                                {/*<span className={'mxd-display-block'}>长按识别二维码</span>*/}
                                {/*<span>获取专属海报，实时查询效益</span>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                {/*<div className="am-modal-footer" onClick={this.closeGzhh}>*/}
                                {/*<div className="am-modal-button-group-v am-modal-button-group-normal" role="group">*/}
                                {/*<a className="am-modal-button" role="button">取消</a>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                {/*</div>:""*/}
                                {/*}*/}

                            </div>

                            <Modal
                                className={'showGzhhCon'}
                                visible={this.state.showGzhh}
                                transparent={true}
                                onClose={this.closeGzhh}
                                footer={[{text: '取消', onPress: this.closeGzhh}]}
                            >
                                <div className={'gzhhCon'}>
                                    <img src={(this.state.gzhImg ? this.state.gzhImg : "")}
                                         className={'gzhhImg'}/>
                                    <div className={'gzhhWord'}>
                                        <span className={'mxd-display-block'}>长按识别二维码，实时查询收益</span>
                                        {/*<span>获取专属海报，实时查询效益</span>*/}
                                    </div>
                                </div>

                            </Modal>
                        </div> :
                        this.state.showToolShare?
                            <div className={'noPayWrapper'} onClick={this.noPayClose.bind(this)}>
                                <div className={'noPayCon'}>
                                    <div className={'noPayBg showToolShare'}>
                                       <i className={'topImage'}></i>
                                        <div className={'shareCon'}>
                                            <p className={'shareTitle'}>购买之后</p>
                                            <p className={'shareDesc'}>才能分享赚佣金哦！</p>
                                            <Button
                                                type={'warning'}
                                                className={'shareBtn'}
                                                onClick={this.gotoPayNext.bind(this,this.props.goodsData)}
                                            >立即购买</Button>
                                            <span className={'shareCloseBtn'} onClick={this.noPayClose.bind(this)}>我在想想</span>
                                        </div>
                                    </div>
                                </div>
                            </div>:""
                }
            </div>


        )
    }
}

export default SharePic;

