import React, {Component} from 'react';
import Loadable from 'react-loadable';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Loading from '../loading'
class App extends Component {
    render() {
        return (
            <div>
                <Route exact path={this.props.match.url+"/:id"} component={
                    Loadable({
                        loader:()=>import('./goods_show'),
                        loading: Loading
                    })
                }/>
            </div>
        );
    }
}

export default App;