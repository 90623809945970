import React, {Component} from 'react'
// import './style/rank.less'
import './ranking'
import Qrcode from '../../image/rank/zf_qrcode.jpg'
// import ShareModal from '../goods/shareMiddle'
import {Path} from '../../path'
import Technology from '../public/technology'
import SharePic from '../public/sharePic/sharePic'

class Rank extends Component {
    constructor(props){
        super(props);
        this.shareFriendsClose=this.shareFriendsClose.bind(this);
        this.changeImg=this.changeImg.bind(this);

        this.state={
            shareFriends:false,
            fansId:null,
            openImgFlag:false
        }
    }

    componentWillMount() {
        // window.Ranking.init();
    }

    componentDidMount() {
        window.Ranking.init();
        document.getElementById('root').className='rankContainer';
        if(!this.props.location.search){
            this.props.history.push(Path.getMine())
        }
    }

    componentWillUnmount() {
        document.getElementById('root').className='';
    }

    shareFriendsClose(){
        this.setState({
            shareFriends:false
        })
    }
    showShareModal(){
        this.setState({
            openImgFlag:true
        })
    }

    changeImg(){
        this.setState({
            openImgFlag:false
        })
    }
    render(){
        return(
            <div id="container" className='rankWrapper'>
                <div className="nav -hide">
                    <ul>
                        <li>
                            <div id="navRank" className="nav-item nav-rank">
                                <span>排行榜</span>
                            </div>
                        </li>
                        <li>
                            <div id="navMyFans" className="nav-item nav-myfans">
                                <span>好友圈</span>
                            </div>
                        </li>
                        <li>
                            <div id="navZf" className="nav-item nav-zf">
                                <span>快涨粉</span>
                            </div>
                        </li>
                    </ul>
                </div>
                {/*<ShareModal close={this.shareFriendsClose} className={this.state.shareFriends?"mxd-display-block":"mxd-display-none"}/>*/}
                <SharePic openImgFlag={this.state.openImgFlag} changeImg={this.changeImg}></SharePic>
                <div id="rankContainer" className="hide">
                    <div>
                        <div className="header-bg">
                        </div>
                        <div className="about-ranking">
                            <ul className="ranking-wrap">
                                <li className="ranking-header">
                                    <ul>
                                        <li className="card">
                                            <ul className="card-people">
                                                <li id="number2" className="number2 rank-li hidden">
                                                    <div className="card-people-img" align="center">
                                                        <img src="https://static.94mxd.com.cn/images/wx_noheadimg.png" />
                                                    </div>
                                                    <p></p>
                                                    <span></span>
                                                </li>
                                                <li id="number1" className="number1 rank-li hidden">
                                                    <div className="path1"></div>
                                                    <div className="path2"></div>
                                                    <div className="card-people-img" align="center">
                                                        <img src="https://static.94mxd.com.cn/images/wx_noheadimg.png" />
                                                    </div>
                                                    <p></p>
                                                    <span></span>
                                                </li>
                                                <li id="number3" className="number2 number3 rank-li hidden">
                                                    <div className="card-people-img" align="center">
                                                        <img src="https://static.94mxd.com.cn/images/wx_noheadimg.png" />
                                                    </div>
                                                    <p></p>
                                                    <span></span>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div id="rankMe" className="rank-me hide hasNoInfo">
                                        <div className="talent-list">
                                            <div className="author">
                                                <span className='num'>0</span>
                                                <img src="https://static.94mxd.com.cn/images/wx_noheadimg.png" />
                                            </div>
                                            <div className="talent-me-r">
                                                <p className='nickName'></p>
                                                <p className='rankInfo'><span>好友助力:<span className='rankNum'>0</span></span></p>
                                            </div>
                                            <div className='invite' onClick={this.showShareModal.bind(this)}>
                                                去邀请
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="talent-rank">
                                    <ul id="talentList">
                                    </ul>
                                </li>
                            </ul>
                            <div className="pullupload">
                                <p></p>
                            </div>
                            <Technology></Technology>
                        </div>
                    </div>
                </div>

                <div id="myFansContainer" className="hide">
                    <div className='myFansWrapper'>
                        <div className="header-bg">
                        </div>
                        <div className="about-fans-ranking">
                            <div className="complete-info">
                                <div className="fans">
                                    <div className="headimg">
                                        <img />
                                            <span></span>
                                    </div>
                                    <p></p>
                                </div>
                                <div className="complete-num">
                                    <span>你当前获得助力</span>
                                    <span className="num">0</span>
                                </div>
                                <div className="complete-progress">
                                    <div className="progress-bar"></div>
                                    <div className="progress"></div>
                                    <div className="lv left hide">
                                        <span></span>
                                        <p></p>
                                    </div>
                                    <div className="lv left2 hide">
                                        <span></span>
                                        <p></p>
                                    </div>
                                    <div className="lv middle hide">
                                        <span></span>
                                        <p></p>
                                    </div>
                                    <div className="lv right hide">
                                        <span></span>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                            <div className="support-list">
                                <p className="no-support">暂时还没有好友支持你哦</p>
                            </div>
                            <Technology></Technology>
                        </div>
                    </div>
                </div>
                <div id="zfContainer" className="hide">
                    <div className="about-zf">
                        <div className="zf-space-top"></div>
                        <div className="zf-logo">
                        </div>
                        <div className="zf-slogan"></div>
                        <div className="zf-pronum">
                            限送<span>200</span>个
                        </div>
                        <div className="zf-qrcode">
                            <img src={Qrcode} />
                        </div>
                        <p className="zf-qrcode-des">长按二维码领取</p>
                        <div className="zf-getfanlist">
                            <div id="zfList">

                            </div>
                        </div>
                        {/*<div className="zf-space"></div>*/}
                        <div className="tf bottomCopyRight">
                            <Technology></Technology>
                        </div>
                    </div>
                </div>



            </div>
        )
    }
}

export default Rank








