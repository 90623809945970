import React, {Component} from 'react'
import {Modal} from 'antd-mobile';
// import '.../../goods/style/index.less'
import Api from "../../../util/Api";
import InviteFriend from '../../../image/goods/inviteFriend.png'
import ShareToFriend from '../../../image/goods/shareToFriend.png'
import ScanToPart from '../../../image/goods/scanToPart.png'
import HoldScan from '../../../image/goods/holdScan.png'

class SharePic extends Component {
    constructor(props) {
        super(props);
        this.changeImg = this.changeImg.bind(this);
        this.getGzhhh = this.getGzhhh.bind(this);
        this.showHelpShare = this.showHelpShare.bind(this);
        this.state = {
            shareImg: "",
            gzhImg: "",
            openImgFlag: false,
            fansId: 0
        }
    }

    componentDidMount() {
        if (this.props.openImgFlag == true) {
            if (this.props.fansId) {
                if (this.props.fansId <= 0) {
                    this.getGzhhh()
                } else {
                    this.setState({
                        fansId: this.props.fansId
                    })
                    this.showHelpShare()
                }
            } else {
                let fansId = window.sessionStorage.getItem("fansId")
                if (fansId <= 0) {
                    this.getGzhhh()
                } else {
                    this.setState({
                        fansId: fansId
                    })
                    this.showHelpShare()
                }
            }

        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.openImgFlag == true) {
            if (nextProps.fansId) {
                // if(nextProps.fansId != this.props.fansId){
                if (nextProps.fansId <= 0) {
                    this.getGzhhh()
                } else {
                    this.setState({
                        fansId: nextProps.fansId
                    })
                    this.showHelpShare()
                }
                // }else{
                //     alert("粉丝ID"+nextProps.fansId)
                //     alert("粉丝ID是否大于0"+nextProps.fansId>0)
                //     if(nextProps.fansId<=0){
                //         this.getGzhhh()
                //     }else{
                //         this.showHelpShare()
                //     }
                // }
            } else {
                let fansId = window.sessionStorage.getItem("fansId")
                if (fansId <= 0) {
                    this.getGzhhh()
                } else {
                    this.setState({
                        fansId: fansId
                    })
                    this.showHelpShare()
                }
            }
        }

    }

    getGzhhh() {
        let id = "", pfs = ""
        if (this.props.id) {
            id = this.props.id
        } else {
            id = window.sessionStorage.getItem("gid")
        }
        if (this.props.pfs) {
            pfs = this.props.pfs
        } else {
            pfs = window.sessionStorage.getItem("pfs")
        }
        Api.getQrcode(id, pfs, (data) => {
            if (data.respCode == 0) {
                this.setState({
                    gzhImg: data.respMsg.baseCode,
                    openImgFlag: true
                })
            }
        })
    }

    showHelpShare() {
        // alert("id="+this.props.id)
        let id = ""
        if (this.props.id) {
            id = this.props.id
        } else {
            id = window.sessionStorage.getItem("gid")
        }
        Api.getPoster(id, (data) => {
            if (data.respCode == 0) {
                this.setState({
                    shareImg: data.respMsg.url,
                    openImgFlag: true
                })
            }
        })

    }

    changeImg() {
        this.setState({
            openImgFlag: false
        })
        this.props.changeImg(false)
    }

    render() {
        return (
            <Modal
                className="goodsData"
                popup
                visible={this.state.openImgFlag}
                onClose={this.changeImg}
                animationType="slide-up"
            >
                {
                    this.state.fansId > 0 ? <div className="goodsPrizeDrawer goodsShare">
                            <div className="goodsPrizeDrawerTop goodsShareHelp">
                                <p className="goodsShareHelpTitle">
                                    <img src={InviteFriend} className='inviteFriend'/>
                                </p>
                                <img
                                    src={(this.state.shareImg && this.state.shareImg.length > 0 ? this.state.shareImg : "")}
                                    className="goodsShareHelpImg"/>
                                <p className="goodsShareHelpFunc">
                                    <img src={ShareToFriend} className='shareToFriend'/>
                                </p>
                            </div>
                            <div className="goodsPrizeDrawerBottom" onClick={this.changeImg}>取消</div>
                        </div> :
                        <div>
                            <div className="goodsPrizeDrawerTop">
                                <p className="goodsShareHelpTitle">
                                    <img src={ScanToPart} className='scanToPart'/>
                                </p>
                                <img className="goodsShareHelpImg"
                                     src={"data:image/jpeg;base64," + (this.state.gzhImg && this.state.gzhImg.length > 0 ? this.state.gzhImg : "")}/>
                                <p className="goodsPrizeDGzhh">
                                    <img src={HoldScan} className='holdScan'/>
                                </p>
                            </div>
                            <div className="goodsPrizeDrawerBottom" onClick={this.changeImg}>取消</div>
                        </div>
                }
            </Modal>

        )
    }
}

export default SharePic;
