import {FetchUtil} from './FetchUtil'
import axios from "axios";

const API_MXD = process.env.REACT_APP_API_MXD;

const URL = {
    example: API_MXD + "/subs/active/list",
    personalHome: API_MXD + "/myshop/personal/home",
    accountInfo: API_MXD + "/myshop/account/info",
    accountPhoneNo: API_MXD + "/myshop/account/phoneNo",
    accountWechatNo: API_MXD + "/myshop/account/wechatNo",
    personalInfo: API_MXD + "/myshop/personal/info",
    addressList: API_MXD + "/myshop/address/list",
    addressInfo: API_MXD + "/myshop/address/info",
    addressEdit: API_MXD + "/myshop/address/edit",
    addressAdd: API_MXD + "/myshop/address/add",
    orderAdd: API_MXD + '/goodsOrder/addMaterial',
    orderLink: API_MXD + '/goodsOrder/addVirtual',
    getSpec: API_MXD + '/goods/spec',
    getPrizesInfo: API_MXD + "/goodsOrder/prizes/info",
    getGoodsStocks: API_MXD + '/goods/stocks',
    orderList: API_MXD + "/goodsOrder/shop/list",
    orderDetail: API_MXD + "/goodsOrder/shop/detail",
    myshopVippay: API_MXD + "/myshop/vippay",

    goodsDetail: API_MXD + "/goods/detail",
    goodsLogin: API_MXD + "/myshop/redirUrl",
    goodsTask: API_MXD + "/myshop/taskposter/info",
    contactway: API_MXD + "/goods/contactway/info",
    getGoodsStatus: API_MXD + "",
    getFansId: API_MXD + "/myshop/fansId",
    getQrcode: API_MXD + "/goods/poster/qrcode",
    getPoster: API_MXD + "/goods/poster/img",
    wechatJSApi: API_MXD + "/wechat/jsapi_sha1",
    shareConfig: API_MXD + "/goods/shareConfig",
    orderNoAddress: API_MXD + "/goodsOrder/noAddress",
    goodsOrderReceipt: API_MXD + "/goodsOrder/receipt",
    goodsOrderReceiveGoods: API_MXD + "/goodsOrder/receiveGoods ",


    goods: API_MXD + "/mdistri/goods",
    poster: API_MXD + "/mdistri/poster",
    ranking: API_MXD + "/mdistri/ranking",
    qocode: API_MXD + "/mdistri/subs_qrcode",
    shareDistriConfig: API_MXD + "/mdistri/wx_shareconfig",
    wxpay: API_MXD + "/mdistri/wxpay",
    wxpayResult: API_MXD + "/mdistri/wxpay/result",
    paysuccess: API_MXD + "/mdistri/paysuccess",
    orderListDistri: API_MXD + "/mdistri/order_list",
    orderCheckQrcode: API_MXD + "/mdistri/order/check_qrcode",
    orderQRCheckComfirm: API_MXD + "/mdistri/order/qrcheck/comfirm",
    orderQRCheckDetail: API_MXD + "/mdistri/order/qrcheck/detail",
    orderDetailDistri: API_MXD + "/mdistri/order_detail",
    subscribeDistri: API_MXD + "/mdistri/subscribe",


    accountCash: API_MXD + "/mdistri/account_cash",
    flowRecord: API_MXD + "/mdistri/flow_record",
    widthDrawApply: API_MXD + "/mdistri/withdraw/apply",
    mdistriToken: API_MXD + "/mdistri/token",
    mdistriGoodsIndex: API_MXD + "/mdistri/goodsindex",
    mdistriPreorder: API_MXD + "/mdistri/preorder",
    mdistriUnifiedorder: API_MXD + "/mdistri/unifiedorder",
    mdistriVippay: API_MXD + "/mdistri/vippay",
    mdistriPartnum: API_MXD + "/mdistri/partnum",
    mdistriBarrage: API_MXD + '/mdistri/barrage',

    // 拼团
    groupBuyDetail: API_MXD + '/mgroupbuy/goods',
    groupBuyPreorder: API_MXD + '/mgroupbuy/preorder',
    groupBuyUnifiedorder: API_MXD + '/mgroupbuy/unifiedorder',
    groupBuyWxpay: API_MXD + '/mgroupbuy/wxpay',
    groupBuyPayReault: API_MXD + '/mgroupbuy/wxpay/result',
    groupBuyPoster: API_MXD + '/mgroupbuy/poster',
    groupBuyShare: API_MXD + '/mgroupbuy/wx_shareconfig',
    groupBuyPaysuccess: API_MXD + '/mgroupbuy/paysuccess',
    groupBuyGroupsuccess: API_MXD + '/mgroupbuy/groupsuccess',
    groupBuyOrderList: API_MXD + '/mgroupbuy/order/list',
    groupBuyOrderDetail: API_MXD + '/mgroupbuy/order',
    groupBuyOrderCancel: API_MXD + '/mgroupbuy/order/cancel',
    groupBuySubscribe: API_MXD + '/mgroupbuy/subscribe',
    groupBuySubQrcode: API_MXD + '/mgroupbuy/subs_qrcode',
    groupBuyWaipay: API_MXD + '/mgroupbuy/grouporder/waitpay',
    groupBuyGroupList: API_MXD + '/mgroupbuy/public/grouplist',
    groupBuyBarrage: API_MXD + '/mgroupbuy/barrage',
    groupBuyCheck: API_MXD + '/mgroupbuy/unifiedorder/check',
    groupBuyVippay: API_MXD + '/mgroupbuy/vippay',
    groupBuyPartnum: API_MXD + '/mgroupbuy/partnum',
    domainmapQuery: API_MXD + '/domainmap/query',

    // 物流查询
    logisticsDataQuery: API_MXD + '/logistics/data/query',
    logisticsDataQueryAll: API_MXD + '/logistics/data/query_all',
    logisticsDataExists: API_MXD + '/logistics/data/exists',
    logisticsTitle: API_MXD + '/logistics/title',
    domainOpenLog: API_MXD + '/domainmap/opened',
    getGoodsFormFields: API_MXD + '/goodsOrder/formfields',
    getGoodsOrderOrNot: API_MXD + '/goodsOrder/orderOrNot',
    editGoodsOrder: API_MXD + '/goodsOrder/editMaterial',
    getOrderCheckDetail: API_MXD + '/goodsOrder/orderCheckDetail',


};

class Api {

    static doGet(url, success, error, tokenData) {
        let fetchUtil = new FetchUtil();
        let token = window.sessionStorage.getItem("token")
        let id = window.sessionStorage.getItem("gid")

        if (url == URL.accountCash) {
            token = tokenData
        }

        if (url != URL.domainmapQuery) {
            if (url.indexOf("?") <= 0) {
                url = url + "?token=" + token
            } else {
                url = url + "&&token=" + token
            }
        }

        axios.get(url)
            .then((msg) => {
                let data = msg.data;
                if (url.indexOf("mdistri") > 0) {
                    if (data.respCode == 1050) {
                        window.location.href = API_MXD + "/distri/goods/detail/" + id + "?pfs="
                    } else {
                        success(JSON.parse(data))
                    }
                } else {
                    success(JSON.parse(data))
                }
            })
            .catch((error) => {
                console.log(error);
            })


        // fetchUtil.init()
        //     .setUrl(url)
        //     .setMethod('GET')
        //     .setCookieCors()
        //     .dofetch()
        //     .then((data) => {
        //         if (success) {
        //             if(url.indexOf("mdistri")>0){
        //                 if(data.respCode == 1050){
        //                     window.location.href = API_MXD+"/distri/goods/detail/"+id+"?pfs="
        //                 }else{
        //                     success(data)
        //                 }
        //             }else{
        //                 success(data)
        //             }
        //
        //         }
        //     })
        //     .catch((errmsg) => {
        //         // alert(url+JSON.stringify(errmsg))
        //         if (error) {
        //             error(errmsg)
        //         }
        //     })
    }

    static doPost(url, bodyType, bodyData, success, error) {
        let fetchUtil = new FetchUtil();
        let token = window.sessionStorage.getItem("token")
        let id = window.sessionStorage.getItem("gid")
        if (bodyData && url != URL.mdistriToken) {
            bodyData["token"] = token
        }
        axios.post(url, bodyData)
            .then((msg) => {
                let data = msg.data;
                if (success) {
                    if (url.indexOf("mdistri") > 0) {
                        if (data.respCode == 1050) {
                            window.location.href = API_MXD + "/distri/goods/detail/" + id + "?pfs="
                        } else {
                            success(JSON.parse(data))
                        }
                    } else {
                        success(JSON.parse(data))
                    }
                }
            })
            .catch((err) => {
                error(err)
                // console.log(error);
            })

        // fetchUtil.init()
        //     .setUrl(url)
        //     .setMethod("POST")
        //     .setCookieCors()
        //     .setBodyType(bodyType)
        //     .setBody(bodyData ? bodyData : {})
        //     .dofetch()
        //     .then((data) => {
        //         if (success) {
        //             if(url.indexOf("mdistri")>0){
        //                 if(data.respCode == 1050){
        //                     window.location.href = API_MXD+"/distri/goods/detail/"+id+"?pfs="
        //                 }else{
        //                     success(data)
        //                 }
        //             }else{
        //                 success(data)
        //             }
        //         }
        //     })
        //     .catch((errmsg) => {
        //         // alert(url+JSON.stringify(errmsg))
        //         if (error) {
        //             error(errmsg);
        //         }
        //     })
    }

    /**
     * 示例
     * @param success
     * @param error
     */
    static example(success, error) {
        this.doGet(URL.example, success, error)
    }

    // 我的首页
    static personalHome(id, success, error) {
        this.doGet(URL.personalHome, success, error)
    }

    // 我的设置
    static accountInfo(id, success, error) {
        this.doGet(URL.accountInfo, success, error)
    }

    // 我的修改手机号
    static accountPhoneNo(type, data, success, error) {
        this.doPost(URL.accountPhoneNo, type, data, success, error)
    }

    // 我的修改微信名
    static accountWechatNo(type, data, success, error) {
        this.doPost(URL.accountWechatNo, type, data, success, error)
    }

    // 我的个人信息
    static personalInfo(id, success, error) {
        this.doGet(URL.personalInfo, success, error)
    }

    // 地址列表
    static addressList(id, success, error) {
        this.doGet(URL.addressList, success, error)
    }

    // 地址列表详情
    static addressInfo(id, success, error) {
        this.doGet(URL.addressInfo + "?addressId=" + id, success, error)
    }

    // 地址详情修改
    static addressEdit(type, data, success, error) {
        this.doPost(URL.addressEdit, type, data, success, error)
    }

    // 地址新增
    static addressAdd(type, data, success, error) {
        this.doPost(URL.addressAdd, type, data, success, error)
    }

    // 订单列表
    static orderList(id, pageSize, pageNum, status, success, error) {
        this.doGet(URL.orderList + "?pageSize=" + pageSize + "&pageNum=" + pageNum + "&status=" + status, success, error)
    }

    // 订单详情
    static orderDetail(id, success, error) {
        this.doGet(URL.orderDetail + "?orderId=" + id, success, error)
    }

    // 订单确认收货
    static goodsOrderReceipt(id, success, error) {
        this.doPost(URL.goodsOrderReceipt, "json", {orderId: id}, success, error)
    }


    // 商品详情
    static goodsDetail(id, success, error) {
        this.doGet(URL.goodsDetail + "?gid=" + id, success, error)
    }

    // 判断有没有登录
    static goodsLogin(id, pfs, success, error) {
        let url = ""
        if (pfs > 0) {
            url = URL.goodsLogin + "?gid=" + id + "&pfs=" + pfs
        } else {
            url = URL.goodsLogin + "?gid=" + id
        }
        this.doPost(url, null, null, success, error)

    }

    // 任务详情数据
    static goodsTask(id, success, error) {
        this.doPost(URL.goodsTask, "json", {gid: id}, success, error)
    }

    // 客服微信
    static contactway(id, success, error) {
        this.doGet(URL.contactway + "?gid=" + id, success, error)
    }


    // 生成订单
    static orderAdd(type, data, success, error) {
        this.doPost(URL.orderAdd, type, data, success, error)
    }

    // 虚拟商品任务完成点击提交
    static orderLink(type, data, success, error) {
        this.doPost(URL.orderLink, type, data, success, error)
    }

    // 获取商品规格
    static getSpec(id, orderId, success, error) {
        this.doGet(URL.getSpec + '?gid=' + id + '&orderId=' + orderId, success, error)
    }

    //商品领取信息页面
    static getPrizesInfo(gid, success, error) {
        this.doGet(URL.getPrizesInfo + '?gid=' + gid, success, error)
    }

    // 获取商品库存
    static getGoodsStocks(spcKey, gid, success, error) {
        this.doGet(URL.getGoodsStocks + '?spcKey=' + spcKey + '&gid=' + gid, success, error)
    }

    // 通过状态显示进行到哪一步
    static getGoodsStatus(id, success, error) {
        this.doGet(URL.getGoodsStatus + '?gid=' + id, success, error)
    }

    // 得到粉丝ID
    static getFansId(id, success, error) {
        let now = new Date();
        this.doGet(URL.getFansId + '?gid=' + id + "&timestamp=" + now.getTime(), success, error)
    }

    // 生成二维码
    static getQrcode(id, pfs, success, error) {
        if (pfs > 0) {
            this.doGet(URL.getQrcode + '?gid=' + id + "&pfs=" + pfs, success, error)
        } else {
            this.doGet(URL.getQrcode + '?gid=' + id, success, error)
        }

    }

    // 生成海报
    static getPoster(id, success, error) {
        this.doGet(URL.getPoster + '?gid=' + id, success, error)
    }

    // 获得签名
    static wechatJSApi(url, success, error) {
        this.doPost(URL.wechatJSApi, "form", {url: url}, success, error)
    }

    // 获得签名
    static shareConfig(gid, pfs, success, error) {
        if (pfs > 0) {
            this.doGet(URL.shareConfig + '?gid=' + gid + '&pfs=' + pfs, success, error)
        } else {
            this.doGet(URL.shareConfig + '?gid=' + gid, success, error)
        }

    }

    // 没有地址生成订单
    static orderNoAddress(id, success, error) {
        this.doPost(URL.orderNoAddress, "json", {gid: id}, success, error)
    }

    // 查询库存
    static orderReceiveGoods(params, success, error) {
        this.doPost(URL.goodsOrderReceiveGoods, "json", params, success, error)
    }

    // 获取是否是付费用户
    static myshopVippay(success, error) {
        let gid = window.sessionStorage.getItem("gid")
        this.doGet(URL.myshopVippay + "?gid=" + gid, success, error)
    }


    // 获取订单列表
    static orderListDistri(status, pageNum, success, error) {
        this.doGet(URL.orderListDistri + '?status=' + status + '&pageNum=' + pageNum + "&pageSize=10", success, error)
    }


    // 获取核销二维码
    static orderCheckQrcode(tradeno, success, error) {
        this.doGet(URL.orderCheckQrcode + '?tradeno=' + tradeno, success, error)
    }


    // 获取核销员订单详情
    static orderQRCheckDetail(success, error) {
        this.doGet(URL.orderQRCheckDetail, success, error)
    }


    // 获取核销订单详情
    static orderDetailDistri(tradeno, success, error) {
        this.doGet(URL.orderDetailDistri + '?tradeno=' + tradeno, success, error)
    }

    // 确认核销
    static orderQRCheckComfirm(success, error) {
        let token = window.sessionStorage.getItem("token")
        this.doPost(URL.orderQRCheckComfirm + "?token=" + token, "json", {}, success, error)
    }

    // 是否关注公众号
    static subscribeDistri(success, error) {
        this.doGet(URL.subscribeDistri, success, error)
    }


    static getGoodsData(success, error) {
        let timeStr = new Date().getTime()
        this.doGet(URL.goods + "?flag=" + timeStr, success, error)
    }

    static getPosterData(success, error) {
        this.doGet(URL.poster, success, error)
    }

    static getRankingData(pageNum, pageSize, success, error) {
        this.doGet(URL.ranking + "?pageSize=" + pageSize + "&pageNum=" + pageNum, success, error)
    }

    static getQrcodeData(success, error) {
        this.doGet(URL.qocode, success, error)
    }

    static getShareConfig(url, success, error) {
        let token = window.sessionStorage.getItem("token")
        this.doPost(URL.shareDistriConfig + "?token=" + token, "json", {url: url}, success, error)
    }

    static getWXPay(tradeno, success, error) {
        let token = window.sessionStorage.getItem("token")
        this.doPost(URL.wxpay + "?token=" + token + "&tradeno=" + tradeno, "json", {}, success, error)
    }

    static getWXPayResult(tradeno, success, error) {
        this.doGet(URL.wxpayResult + "?tradeno=" + tradeno, success, error)
    }

    static getPaySuccess(success, error) {
        this.doGet(URL.paysuccess, success, error)
    }

    static getAccountCash(token, success, error) {
        this.doGet(URL.accountCash, success, error, token)
    }

    static getFlowRecord(token, status, index, success, error) {
        let tokenData = window.sessionStorage.getItem("token")
        if (!tokenData) {
            tokenData = token
        }
        this.doPost(URL.flowRecord + "?token=" + tokenData + "&pageSize=10&pageNum=" + index, "json", {status: status}, success, error)
    }

    static getWidthDrawApply(amount, success, error) {
        let token = window.sessionStorage.getItem("token")
        this.doPost(URL.widthDrawApply + "?token=" + token, "json", {amount: amount}, success, error)
    }

    static getMdistriToken(predata, success, error) {
        this.doPost(URL.mdistriToken, "json", {predata: predata}, success, error)
    }

    static getGoodsIndex(success, error) {
        this.doGet(URL.mdistriGoodsIndex, success, error)
    }

    static mdistriPreorder(success, error) {
        this.doGet(URL.mdistriPreorder, success, error)
    }

    static mdistriVippay(success, error) {
        this.doGet(URL.mdistriVippay, success, error)
    }

    static mdistriPartnum(success, error) {
        this.doGet(URL.mdistriPartnum, success, error)
    }

    static mdistriBarrage(pageNum, pageSize, success, error) {
        this.doGet(URL.mdistriBarrage + "?pageNum=" + pageNum + "&pageSize=" + pageSize, success, error)
    }

    static mdistriUnifiedorder(params, success, error) {
        let token = window.sessionStorage.getItem("token")
        this.doPost(URL.mdistriUnifiedorder + "?token=" + token, "json", params, success, error)
    }

    static getGroupBuyDetail(success, error) {
        this.doGet(URL.groupBuyDetail, success, error)
    }

    static getGroupBuyPreorder(success, error) {
        this.doGet(URL.groupBuyPreorder, success, error)
    }

    static getGroupBuyWxpay(tradeno, success, error) {
        let tokenData = window.sessionStorage.getItem("token")
        this.doPost(URL.groupBuyWxpay + "?tradeno=" + tradeno + '&token=' + tokenData, 'json', {}, success, error)
    }

    static getGroupBuyPayReault(tradeno, success, error) {
        this.doGet(URL.groupBuyPayReault + "?tradeno=" + tradeno, success, error)
    }

    static getGroupBuyPoster(id, success, error) {
        this.doGet(URL.groupBuyPoster + "?groupId=" + id, success, error)
    }

    static getGroupBuyShare(url, groupId, success, error) {
        let tokenData = window.sessionStorage.getItem("token")
        this.doPost(URL.groupBuyShare + "?token=" + tokenData, "json", {url: url, groupId: groupId}, success, error)
    }

    static groupBuyPaysuccess(type, tradeno, success, error) {
        this.doGet(URL.groupBuyPaysuccess + "?" + type + "=" + tradeno, success, error)
    }

    static groupBuyUnifiedorder(params, success, error) {
        let tokenData = window.sessionStorage.getItem("token")
        this.doPost(URL.groupBuyUnifiedorder + "?token=" + tokenData, "json", params, success, error)
    }

    // 订单
    static groupBuyOrderList(status, pageNum, success, error) {
        this.doGet(URL.groupBuyOrderList + "?status=" + status + '&pageNum=' + pageNum + "&pageSize=10", success, error)
    }

    // 订单详情
    static groupBuyOrderDetail(tradeno, success, error) {
        this.doGet(URL.groupBuyOrderDetail + "?tradeno=" + tradeno, success, error)
    }

    // 订单取消
    static groupBuyOrderCancel(tradeno, success, error) {
        let tokenData = window.sessionStorage.getItem("token")
        this.doPost(URL.groupBuyOrderCancel + "?token=" + tokenData, "json", {tradeno: tradeno}, success, error)
    }

    // 公众号
    static groupBuySubscribe(success, error) {
        this.doGet(URL.groupBuySubscribe, success, error)
    }

    // 公众号二维码
    static groupBuySubQrcode(groupId, success, error) {
        this.doGet(URL.groupBuySubQrcode + "?groupId=" + groupId, success, error)
    }

    // 付款成功后页面信息
    static groupBuyGroupsuccess(tradeno, success, error) {
        this.doGet(URL.groupBuyGroupsuccess + `?tradeno=${tradeno}`, success, error)
    }

    // 获取用户在该团下的待支付订单
    static groupBuyWaipay(groupId, success, error) {
        this.doGet(URL.groupBuyWaipay + "?groupId=" + groupId, success, error)
    }

    // 获取该商品所有的拼团列表
    static groupBuyGroupList(pageNum, pageSize, success, error) {
        this.doGet(URL.groupBuyGroupList + "?pageNum=" + pageNum + "&pageSize=" + pageSize, success, error)
    }

    // 获取拼团弹幕列表
    static groupBuyBarrage(pageNum, pageSize, success, error) {
        this.doGet(URL.groupBuyBarrage + "?pageNum=" + pageNum + "&pageSize=" + pageSize, success, error)
    }

    // 获取是否是付费用户
    static groupBuyVippay(success, error) {
        this.doGet(URL.groupBuyVippay, success, error)
    }

    // 获取参与人数
    static groupBuyPartnum(success, error) {
        this.doGet(URL.groupBuyPartnum, success, error)
    }

    // 下单之前校验
    static groupBuyCheck(params, success, error) {
        let tokenData = window.sessionStorage.getItem("token")
        this.doPost(URL.groupBuyCheck + "?token=" + tokenData, "json", params, success, error)
    }

    // 下单之前校验
    static domainmapQuery(success, error) {
        this.doGet(URL.domainmapQuery + "?domainKey=WX_JSPAY", success, error)
    }

    static logisticsDataQuery(params, success, error) {
        let url = URL.logisticsDataQuery;
        this.doPost(url, 'json', params, success, error);
    }

    static logisticsDataQueryAll(params, success, error) {
        let url = URL.logisticsDataQueryAll;
        this.doPost(url, 'json', params, success, error);
    }

    static logisticsDataExists(params, success, error) {
        this.doPost(URL.logisticsDataExists, "json", params, success, error);
    }

    static logisticsTitle(str, success, error) {
        let url = URL.logisticsTitle;
        this.doGet(url + '?' + str, success, error);
    }

    /**
     * 域名点击
     * @param dlogid
     * @param success
     * @param error
     */
    static domainOpenLog(dlogid, success, error) {
        this.doGet(URL.domainOpenLog + '?dlogid=' + dlogid, success, error)
    }

    /**
     * 获取商品采集信息表单
     * @param gid
     * @param success
     * @param error
     */
    static getGoodsFormFields(gid, orderId, success, error) {
        this.doGet(URL.getGoodsFormFields + '?gid=' + gid + '&orderId=' + orderId, success, error)
    }

    /**
     * 判断用户是否下单
     * @param gid
     * @param success
     * @param error
     */
    static getGoodsOrderOrNot(gid, fansId, success, error) {
        this.doGet(URL.getGoodsOrderOrNot + '?gid=' + gid + '&fansId=' + fansId, success, error)
    }

    /**
     * 编辑订单信息
     * @param params
     * @param success
     * @param error
     */
    static editGoodsOrder(params, success, error) {
        this.doPost(URL.editGoodsOrder, "json", params, success, error);
    }

    /**
     * 获取订单核销详情
     * @param orderId
     * @param success
     */
    static getOrderCheckDetail(orderId, success, error) {
        this.doGet(URL.getOrderCheckDetail + '?orderId=' + orderId, success, error);
    }
}

export default Api

