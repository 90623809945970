import React,{Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Loading from "../../loading";
import Loadable from 'react-loadable';


class Order extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div>
                <Route path={this.props.match.url + "/list"} component={
                    Loadable({
                        loader: () => import('./order_index'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/detail"} component={
                    Loadable({
                        loader: () => import('./order_detail'),
                        loading: Loading
                    })
                }/>

            </div>
        )
    }


}


export default Order