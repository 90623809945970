import React,{Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Loading from "../loading";
import Loadable from 'react-loadable';


class Mine extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div>
                <Route path={this.props.match.url + "/index"} component={
                    Loadable({
                        loader: () => import('./mineIndex'),
                        // loader: () => import('../loading'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/address"} component={
                    Loadable({
                        loader: () => import('./address/address'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/createAddress"} component={
                    Loadable({
                        loader: () => import('./address/create_address'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/accountSet"} component={
                    Loadable({
                        loader: () => import('./set/set.js'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/changePhone"} component={
                    Loadable({
                        loader: () => import('./set/phone'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/mineMsg"} component={
                    Loadable({
                        loader: () => import('./mineMsg/mine_msg'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/changeName"} component={
                    Loadable({
                        loader: () => import('./mineMsg/changeName/change_name'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/changeVXName"} component={
                    Loadable({
                        loader: () => import('./mineMsg/changeName/change_vx_name.js'),
                        loading: Loading
                    })
                }/>

                <Route path={this.props.match.url + "/order"} component={
                    Loadable({
                        loader: () => import('./order/order_index'),
                        loading: Loading
                    })
                }/>

                <Route path={this.props.match.url + "/myorder"} component={
                    Loadable({
                        loader: () => import('./order/order_detail'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/orderCheck"} component={
                    Loadable({
                        loader: () => import('./order/order_check'),
                        loading: Loading
                    })
                }/>





            </div>
        )
    }


}


export default Mine