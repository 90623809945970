import React from 'react'
import {DatePicker, InputItem, List, Picker, Toast} from "antd-mobile";
import Util from "../../util/util";
import moment from 'moment'

let antdDistrict = [];
let dateFormat = 'YYYY-MM-DD';
let timeFormat = 'HH:mm';
let datetimeFormat = 'YYYY-MM-DD HH:mm';

class FormFields extends React.Component {

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.state = {
            fields: []
        }
    }

    componentDidMount() {
        if (this.props.fields) {
            this.setState({
                fields: this.props.fields
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (JSON.stringify(this.state.fields) != JSON.stringify(nextProps.fields)) {
            this.setState({
                fields: nextProps.fields
            })
        }
    }

    inputChange(index, val) {
        let fields = this.state.fields;
        fields[index].value = val;
        this.setState({
            fields: fields
        })
    }

    dateChange(index, mode, val) {
        let format = dateFormat;
        if (mode == 'datetime') {
            format = datetimeFormat;
        } else if (mode == 'date') {
            format = dateFormat;
        } else if (mode == 'time') {
            format = timeFormat;
        }
        let fields = this.state.fields;
        fields[index].value = moment(val).format(format);
        this.setState({
            fields: fields
        })
    }

    addressChange(index, val) {
        let fields = this.state.fields;
        let onlyData = fields[index].onlyData;
        if (!onlyData) {
            onlyData = [];
        }
        onlyData.addressCode = val;
        fields[index].onlyData = onlyData;
        this.setState({
            fields: fields
        })
    }

    selectOptionChange(index, val) {
        let fields = this.state.fields;
        fields[index].value = val[0];
        this.setState({
            fields: fields
        })
    }

    addressDetailChange(index, val) {
        let fields = this.state.fields;
        let onlyData = fields[index].onlyData;
        if (!onlyData) {
            onlyData = [];
        }
        onlyData.addressDetail = val;
        fields[index].onlyData = onlyData;
        this.setState({
            fields: fields
        })
    }

    getFieldsHtml(field, index) {
        let type = field.type;
        if (type == 1) {
            //手机号
            return <InputItem
                placeholder={"请输入" + field.desc}
                type="number"
                maxLength={11}
                value={field.value}
                onChange={this.inputChange.bind(this, index)}
                onVirtualKeyboardConfirm={this.inputChange.bind(this, index)}>{field.desc}</InputItem>
        } else if (type == 2) {
            //文本格式
            return <InputItem
                placeholder={"请输入" + field.desc}
                type="text"
                value={field.value}
                onChange={this.inputChange.bind(this, index)}
                onVirtualKeyboardConfirm={this.inputChange.bind(this, index)}
            >{field.desc}</InputItem>
        } else if (type == 3) {
            //日期
            return <DatePicker mode='date'
                               value={field.value ? moment(field.value, dateFormat).toDate() : null}
                               extra={<span className="addressSelectExtra">请选择</span>}
                               onChange={this.dateChange.bind(this, index, 'date')}>
                <List.Item arrow="horizontal">{field.desc}</List.Item>
            </DatePicker>
        } else if (type == 4) {
            //数字
            return <InputItem
                placeholder={"请输入" + field.desc}
                type="number"
                value={field.value}
                onChange={this.inputChange.bind(this, index)}
                onVirtualKeyboardConfirm={this.inputChange.bind(this, index)}
            >{field.desc}</InputItem>
        } else if (type == 5) {
            //身份证
            return <InputItem
                placeholder={"请输入" + field.desc}
                type="text"
                value={field.value}
                onChange={this.inputChange.bind(this, index)}
                onVirtualKeyboardConfirm={this.inputChange.bind(this, index)}
            >{field.desc}</InputItem>
        } else if (type == 6) {
            //邮箱
            return <InputItem
                placeholder={"请输入" + field.desc}
                type="email"
                value={field.value}
                onChange={this.inputChange.bind(this, index)}
                onVirtualKeyboardConfirm={this.inputChange.bind(this, index)}
            >{field.desc}</InputItem>
        } else if (type == 7) {
            //时间
            let dateStr = '2021-03-01 ' + field.value;
            return <DatePicker mode='time'
                               value={field.value ? moment(field.value, timeFormat).toDate() : null}
                               extra={<span className="addressSelectExtra">请选择</span>}
                               onChange={this.dateChange.bind(this, index, 'time')}>
                <List.Item arrow="horizontal">{field.desc}</List.Item>
            </DatePicker>
        } else if (type == 8) {
            //日期时间
            return <DatePicker mode='datetime'
                               value={field.value ? moment(field.value, datetimeFormat).toDate() : null}
                               extra={<span className="addressSelectExtra">请选择</span>}
                               onChange={this.dateChange.bind(this, index, 'datetime')}>
                <List.Item arrow="horizontal">{field.desc}</List.Item>
            </DatePicker>
        } else if (type == 9) {
            //地址
            return <span>
                <Picker extra={<span className="addressSelectExtra">地区信息</span>}
                        data={antdDistrict}
                        value={field.onlyData ? field.onlyData.addressCode : null}
                        title=""
                        onOk={this.addressChange.bind(this, index)}
                        onDismiss={e => console.log('dismiss', e)}>
                    <List.Item arrow="horizontal">所在区域</List.Item>
                </Picker>
                <InputItem
                    placeholder="街道门牌信息"
                    type="text"
                    value={field.onlyData ? field.onlyData.addressDetail : null}
                    onChange={this.addressDetailChange.bind(this, index)}
                    onVirtualKeyboardConfirm={this.addressDetailChange.bind(this, index)}
                >详细地址</InputItem>
            </span>
        } else if (type == 10) {
            let options = field.options;
            if (!options) {
                options = [];
            }
            options.map((data) => {
                data.label = data.value
            });
            return <Picker extra={<span className="addressSelectExtra">请选择</span>}
                           data={options}
                           value={[field.value]}
                           cols={1}
                           onOk={this.selectOptionChange.bind(this, index)}>
                <List.Item arrow="horizontal">{field.desc}</List.Item>
            </Picker>
        }
    }

    getData() {
        let fields = this.state.fields;
        let formContent = [];
        for (let i = 0; i < fields.length; i++) {
            let field = fields[i];
            let desc = field.desc;
            let type = field.type;
            let value = field.value;
            if (type == 9) {
                //地址
                let onlyData = field.onlyData;
                if (!onlyData) {
                    Toast.info("请选择所在区域", 3, null, false);
                    return
                } else {
                    if (!onlyData.addressCode) {
                        Toast.info("请选择所在区域", 3, null, false);
                        return
                    }
                    if (!onlyData.addressDetail) {
                        Toast.info("请输入详细地址", 3, null, false);
                        return
                    }
                }
                formContent.push({
                    desc: desc,
                    value: Util.getAntdDistrictZh(onlyData.addressCode, antdDistrict) + ' ' + onlyData.addressDetail,
                    onlyData: {
                        addressCode: onlyData.addressCode,
                        addressDetail: onlyData.addressDetail
                    }
                })
            } else if (type == 1) {
                //手机号
                if (!value) {
                    Toast.info("请输入" + desc, 3, null, false);
                    return
                }
                value = value.replace(/\s+/g, "");
                if (!Util.isPhoneNo(value)) {
                    Toast.info(desc + '格式不正确', 3, null, false);
                    return
                }
                formContent.push({
                    desc: desc,
                    value: value
                })
            } else if (type == 3) {
                //日期
                if (!value) {
                    Toast.info("请选择" + desc, 3, null, false);
                    return
                }
                formContent.push({
                    desc: desc,
                    value: value
                })
            } else if (type == 7) {
                //时间
                if (!value) {
                    Toast.info("请选择" + desc, 3, null, false);
                    return
                }
                formContent.push({
                    desc: desc,
                    value: value
                })
            } else if (type == 8) {
                //时间
                if (!value) {
                    Toast.info("请选择" + desc, 3, null, false);
                    return
                }
                formContent.push({
                    desc: desc,
                    value: value
                })
            } else if (type == 5) {
                //身份证
                if (!value) {
                    Toast.info("请输入" + desc, 3, null, false);
                    return
                }
                if (!Util.isCard(value)) {
                    Toast.info(desc + '格式不正确', 3, null, false);
                    return
                }
                formContent.push({
                    desc: desc,
                    value: value
                })
            } else if (type == 6) {
                //邮箱
                if (!value) {
                    Toast.info("请输入" + desc, 3, null, false);
                    return
                }
                if (!Util.isEmail(value)) {
                    Toast.info(desc + '格式不正确', 3, null, false);
                    return
                }
                formContent.push({
                    desc: desc,
                    value: value
                })
            } else if (type == 10) {
                if (!value) {
                    Toast.info("请选择" + desc, 3, null, false);
                    return
                }
                formContent.push({
                    desc: desc,
                    value: value
                })
            } else {
                if (!value) {
                    Toast.info("请输入" + desc, 3, null, false);
                    return
                }
                formContent.push({
                    desc: desc,
                    value: value
                })
            }
        }
        return formContent;
    }

    render() {
        let districtData = require('../public/location');
        Object.keys(districtData).forEach((index) => {
            let itemLevel1 = {};
            let itemLevel2 = {};
            itemLevel1.value = districtData[index].code;
            itemLevel1.label = districtData[index].name;
            itemLevel1.children = [];
            let data = districtData[index].cities;
            Object.keys(data).forEach((index) => {
                itemLevel2.value = data[index].code;
                itemLevel2.label = data[index].name;
                itemLevel2.children = [];
                let data2 = data[index].districts;
                let itemLevel3 = {};
                itemLevel3.children = [];
                Object.keys(data2).forEach((index) => {
                    itemLevel3.value = index;
                    itemLevel3.label = data2[index];
                    itemLevel2.children.push(itemLevel3);
                    itemLevel3 = {};
                });
                itemLevel1.children.push(itemLevel2);
                itemLevel2 = {};
            });
            antdDistrict.push(itemLevel1)
        });
        return (
            <ul className='createAddress'>
                {
                    this.state.fields ? this.state.fields.map((field, index) => {
                        return <li key={index}>
                            {this.getFieldsHtml(field, index)}
                        </li>
                    }) : ''
                }
            </ul>
        )
    }
}

export default FormFields