import React, {Component} from 'react';
import Loadable from 'react-loadable';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Loading from '../loading'
class Distri extends Component {
    render() {
        return (
            <div>
                <Route path={this.props.match.url+"/index"} component={
                    Loadable({
                        loader:()=>import('./cashList'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url+"/money"} component={
                    Loadable({
                        loader: () => import('./cashMoney'),
                        loading: Loading
                    })
                }/>
            </div>
        );
    }
}

export default Distri;