import React, {Component} from 'react'
import $ from 'jquery';
import {Modal} from "antd-mobile"
// import "../../style/core.less"
// import "../index.less"
import Img1 from "../../image/technology/distriEWM.jpg"
import Img2 from "../../image/technology/goodsEWM.jpg"
import Img3 from "../../image/technology/groupbuyEWM.jpg"
import Api from "../../util/Api";
import {Toast} from "antd-mobile/lib/index";

class Technology extends Component {
    constructor(props) {
        super(props)
        this.closeService = this.closeService.bind(this)
        this.getWord = this.getWord.bind(this)
        this.setDistri = this.setDistri.bind(this)
        this.setGroupBuy = this.setGroupBuy.bind(this)
        this.setGoodsData = this.setGoodsData.bind(this)
        this.showModal = this.showModal.bind(this)
        this.state = {
            tooltip: "",
            qrCodeFlag: "",
            flag: '',
            qrcode: "",
            showFlag: 0
        }
    }

    componentDidMount() {
        document.getElementById("technology").style.display = "block"
        this.getWord()
    }

    componentWillUnmount() {
        // if(this.props.footer == true){
        document.getElementById("technology").parentNode.style.minHeight = "auto"
        // }
    }

    setDistri(){
        if(window.sessionStorage.getItem("token")){
            Api.mdistriVippay((res) => {
                // alert(JSON.stringify(res))
                if (res.respCode == 0) {
                    if (res.respMsg.isPay == 1) {
                        // this.setState({
                        //     showFlag: res.respMsg.isPay,
                        //
                        // })
                        this.setState({
                            tooltip: "",
                            flag: "distri",
                            qrcode: Img1,
                        })
                    } else {
                        let url = window.location.href, tooltip=""
                        if((url.indexOf("/mshop/distri/goods") >= 0) || (url.indexOf("/mshop/distri/rank/") >= 0) ||
                            (url.indexOf("/mshop/distri/cash") >= 0) ||(url.indexOf("/mshop/distri/order") >= 0)){
                            tooltip = "点击免费创建分销"
                        }
                        this.setState({
                            tooltip: tooltip,
                            flag: "distri",
                            qrcode: Img1,
                        })
                    }
                }
            }, () => {
                Toast.info("分销高级版系统错误", 3, null, false)
            })
        }else{
            setTimeout(()=>{
                this.setDistri()
            },200)
        }
    }

    setGroupBuy(){
        if(window.sessionStorage.getItem("token")){
            Api.groupBuyVippay((res) => {
                // alert(JSON.stringify(res))
                if (res.respCode == 0) {
                    if (res.respMsg.isPay == 1) {
                        // this.setState({
                        //     showFlag: res.respMsg.isPay
                        // })
                        this.setState({
                            tooltip: "",
                            flag: "groupbuy",
                            qrcode: Img3,
                        })
                    } else {
                        let url = window.location.href, tooltip=""
                        if((url.indexOf("/mshop/groupbuy/goods") >= 0) || (url.indexOf("/mshop/groupbuy/order") >= 0)){
                            tooltip = "点击免费创建拼团"
                        }
                        this.setState({
                            tooltip: tooltip,
                            flag: "groupbuy",
                            qrcode: Img3,
                        })
                    }
                }
            }, () => {
                Toast.info("拼团高级版系统错误", 3, null, false)
            })
        }else{
            setTimeout(()=>{
                this.setGroupBuy()
            },200)
        }
    }

    setGoodsData(){
        if(window.sessionStorage.getItem("token")){
            Api.myshopVippay((res) => {
                if (res.respCode == 0) {
                    if (res.respMsg.level == 1) {
                        // this.setState({
                        //     showFlag: res.respMsg.isPay
                        // })
                        let url = window.location.href,
                            tooltip=""
                        // if((url.indexOf("/mshop/receive")>=0)){
                        //     tooltip="点击免费创建裂变活动"
                        // }
                        this.setState({
                            tooltip: tooltip,
                            flag: "goods",
                            qrcode: Img2,
                        })
                    } else {
                        let url = window.location.href,
                            tooltip=""
                        if(!((url.indexOf("/mshop/receive")>=0))){
                            tooltip="点击免费创建裂变活动"
                        }
                        this.setState({
                            tooltip: tooltip,
                            flag: "goods",
                            qrcode: Img2,
                        })
                    }
                }
            }, () => {
                Toast.info("任务宝高级版系统错误", 3, null, false)
            })
        }else{
            setTimeout(()=>{
                this.setGoodsData()
            },200)
        }
    }




    getWord() {
        let url = window.location.href
        if (url.indexOf("/distri/") >= 0) {
            this.setDistri()
        } else if (url.indexOf("/groupbuy/") >= 0) {
            this.setGroupBuy()
        } else {
            this.setGoodsData()
        }

    }

    showModal() {
        this.setState({
            qrCodeFlag: true
        })
    }

    closeService() {
        this.setState({
            qrCodeFlag: false
        })
    }

    render() {
        return (
            <span>
                {
                    this.state.showFlag==1 ? "" :
                        <span>
                            <div id="technology" className="technology">
                                <i className="icon-Technology"></i>
                                <p className="technologyWord">提供技术支持</p>
                                {
                                    this.state.tooltip&&this.state.tooltip.length>0?
                                        <p className="addCutom" onClick={this.showModal}>{this.state.tooltip}</p>:
                                        <p className="addCutom">{this.state.tooltip}</p>
                                }

                            </div>
                            <Modal
                                title={'联系客服创建活动'}
                                visible={this.state.qrCodeFlag}
                                transparent
                                onClose={this.closeService}
                                footer={[{text: "确定", onPress: this.closeService}]}
                            >

                                <div className="technologyCon">
                                    <img src={this.state.qrcode} className="serviceImg"/>
                                    <p className="serviceDesc">扫码添加客服微信</p>
                                </div>

                            </Modal>
                        </span>
                }
            </span>

        )
    }
}

export default Technology








