import React, {Component} from 'react';
import Loadable from 'react-loadable';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Loading from './pages/loading'
import Receive from './pages/receive/receive'
import Rank from './pages/tprank/rank'

import GoodsIndex from "./pages/goods/goods"
import Mine from "./pages/mine/mine"
import Order from "./pages/mine/order/order"

import Success from "./pages/success/index"
import DistributionRank from './pages/distributionRank/rank'

import DistributionIndex from "./pages/distribution/goods"
import DistriCash from "./pages/distriCash/distriCash"
import DistriOpenPay from "./pages/distriOpen/open_pay"
import DistriOrder from "./pages/distriOrder/order"
import GroupBuy from './pages/groupbuy/goods'
import Logistics from './pages/logistics/index'
import Api from "./util/Api";
import Util from "./util/util";


class App extends Component {

    componentDidMount() {
        let dlogid = Util.getArgs("dlogid");
        if (dlogid) {
            Api.domainOpenLog(dlogid, function (data) {

            })
        }
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/mshop/goods" component={GoodsIndex}/>
                    <Route path="/mshop/mine" component={Mine}/>
                    <Route path="/mshop/order" component={Order}/>
                    <Route path="/mshop/receive" component={Receive}/>
                    <Route path="/mshop/rank" component={Rank}/>
                    <Route path="/mshop/distri/goods" component={DistributionIndex}/>
                    <Route path="/mshop/distri/success" component={Success}/>
                    <Route path="/mshop/distri/rank/:id" component={DistributionRank}/>
                    <Route path="/mshop/distri/cash" component={DistriCash}/>
                    <Route path="/mshop/distri/openPay" component={DistriOpenPay}/>
                    <Route path="/mshop/distri/order" component={DistriOrder}/>
                    <Route path="/mshop/groupbuy" component={GroupBuy}/>
                    <Route path="/mshop/load" component={Loading}/>
                    <Route path="/mshop/logistics" component={Logistics}/>
                    {/*<Route path="/mshop/order/mdistri" component={Order}/>*/}
                    {/*<Route exact path="/goods/:id" component={Goods}/>*/}
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
