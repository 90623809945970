import React,{Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Loading from "../loading";
import Loadable from 'react-loadable';

class Success extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div>
                <Route path={this.props.match.url} component={
                    Loadable({
                        loader: () => import('./success'),
                        loading: Loading
                    })
                }/>

            </div>
        )
    }


}


export default Success