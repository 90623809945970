import $ from 'jquery';
import BScroll from 'better-scroll';

(function (ranking) {
        const baseUrl = 'https://www.94mxd.com.cn';
        // const API_MXD = process.env.REACT_APP_API_MXD;
        // console.log(API_MXD)

        // getParams()
        // var token = ""
        // if(getParams().length>0 && getParams()!=0){
        //     token = getParams()
        //     console.log(token)
        //     window.sessionStorage.setItem("token",token)
        // }else{
        //     token = window.sessionStorage.getItem("token")
        // }
        ranking.pageNum = 1;
        ranking.supportPageNum = 1;

        ranking.getArgs = function (name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.search.substr(1).match(reg);
            if (r != null) return decodeURIComponent(r[2]);
            return null;
        };

        ranking.init = function () {
            // let tindex = ranking.getArgs('tindex');
            // if (tindex == null) {
            //     tindex = 1;
            // } else {
            //     tindex = Number(tindex);
            // }
            // if (tindex <= 1) {
            //排行榜
            $("#navRank").addClass('active');
            $("#rankContainer").removeClass('hide');
            $("#rankMe").removeClass('hide');
            // } else if (tindex == 2) {
            //     //好友圈
            //     $("#navMyFans").addClass('active');
            //     $("#myFansContainer").removeClass('hide');
            // }
            initRankScroll();
            initMyFansScroll();
            loadRanking(ranking.pageNum);
            loadFansSupportInfo();
            loadFansSupport(ranking.supportPageNum);
        };

        function getParams() {
            var htmlHref = window.location.href;
            htmlHref = htmlHref.replace(/^http:\/\/[^/]+/, "");
            var addr = htmlHref.substr(htmlHref.lastIndexOf('/', htmlHref.lastIndexOf('/') - 1) + 1);
            var index = addr.lastIndexOf("\/");
            //js 获取字符串中最后一个斜杠后面的内容
            var addrLast = decodeURI(addr.substring(index + 1, addr.length));
            //js 获取字符串中最后一个斜杠前面的内容
            var str = decodeURI(addr.substring(0, index));
            return addrLast
        }

        function loadRanking(pageNum) {
            // var tp = ranking.getArgs('tp');
            var rankScroll = ranking.rankScroll;
            var initNav = ranking.initNav;
            var initZF = ranking.initZF;
            var token = window.sessionStorage.getItem("token")
            if (getParams().length > 0 && getParams() != 0) {
                token = getParams()
                window.sessionStorage.setItem("token", token)
            }
            // if (tp) {
            //     let rankingInfo = {
            //         list: [{
            //             headimgurl: "http://pic13.nipic.com/20110409/7119492_114440620000_2.jpg",
            //             nickname: "美眉",
            //             num: 1,
            //             score: 300
            //         },{
            //             headimgurl: "http://pic13.nipic.com/20110409/7119492_114440620000_2.jpg",
            //             nickname: "美眉",
            //             num: 2,
            //             score: 300
            //         },{
            //             headimgurl: "http://pic13.nipic.com/20110409/7119492_114440620000_2.jpg",
            //             nickname: "美眉",
            //             num: 3,
            //             score: 300
            //         },{
            //             headimgurl: "http://pic13.nipic.com/20110409/7119492_114440620000_2.jpg",
            //             nickname: "美眉",
            //             num: 4,
            //             score: 300
            //         },]
            //         // list:[]
            //     }
            // if(rankingInfo.list&&rankingInfo.list.length<=0){
            //     $("#rankEmpty").removeClass('hide')
            //     $("#distriDataNull").removeClass('hide')
            //     $("#rankingWrap").addClass('hide')
            //     $("#container").addClass('rankDistri')
            //     $("#distriData").addClass('hide')
            // }
            //     if (rankingInfo) {
            //         for (var i = 0; i < rankingInfo.list.length; i++) {
            //             var fans = rankingInfo.list[i];
            //             var num = fans.num;
            //             if (num < 4) {
            //                 var headimgurl = fans.headimgurl ? fans.headimgurl : 'http://static.94mxd.com/images/wx_noheadimg.png';
            //                 var nickname = fans.nickname ? fans.nickname : '';
            //                 var talent = '<li class="talent-list rankList">' +
            //                     '<div class="author">' +
            //                     '<span class="num"></span>' +
            //                     '<i class="rankNumber number'+num+'"></i>'+
            //                     '<img src="' + headimgurl + '">' +
            //                     '<div class="talent-me-r">' +
            //                     '<p class="nickName">' + nickname + '</p>' +
            //                     '<p class="rankInfo"><span>累计收益<span class="rankNum">'+ fans.score +'</span>元</span></p>' +
            //                     '</div>'+
            //                     '</div>' +
            //                     '</li>';
            //                 $("#talentList").append(talent);
            //             } else {
            //                 var headimgurl = fans.headimgurl ? fans.headimgurl : 'http://static.94mxd.com/images/wx_noheadimg.png';
            //                 var nickname = fans.nickname ? fans.nickname : '';
            //                 var talent = '<li class="talent-list">' +
            //                                 '<div class="author">' +
            //                                     '<span class="num">' + num + '</span>' +
            //                                     '<img src="' + headimgurl + '">' +
            //                                     '<div class="talent-me-r">' +
            //                                         '<p class="nickName">' + nickname + '</p>' +
            //                                         '<p class="rankInfo"><span>累计收益<span class="rankNum">'+ fans.score +'</span>元</span></p>' +
            //                                     '</div>'+
            //                                 '</div>' +
            //                     '</li>';
            //                 $("#talentList").append(talent);
            //             }
            //         }
            //     }
            $.ajax({
                url: baseUrl + '/mxd/mdistri/ranking?token=' + token + '&pageNum=' + pageNum + '&pageSize=50',
                type: 'GET',
                timeout: 5000,
                success: function (data) {
                    data = JSON.parse(data);
                    if (data.respCode == 0) {
                        var result = data.respMsg;
                        if (result.isTpVip) {
                            initNav(1);
                            document.title = "收益排行";
                        } else {
                            initNav(0);
                            initZF()
                        }
                        var rankingInfo = result
                        var pagInfo = result.pagInfo;
                        $("#rankImg")[0].src = result.coverImgUrl
                        if (pageNum >= pagInfo.pages && pagInfo.total > pagInfo.pageSize) {
                            $(".pullupload").show();
                            $(".pullupload p").html('没有更多了');
                        }
                        if (rankingInfo.list && rankingInfo.list.length <= 0) {
                            $("#rankEmpty").removeClass('hide')
                            $("#distriDataNull").removeClass('hide')
                            $("#rankingWrap").addClass('hide')
                            $("#container").addClass('rankDistri')
                            $("#distriData").addClass('hide')
                        }
                        // if (rankingInfo) {
                        //     for (var i = 0; i < rankingInfo.list.length; i++) {
                        //         var fans = rankingInfo.list[i];
                        //         var num = fans.ranking+((pagInfo.pageNum-1)*pagInfo.pageSize);
                        //         if (num < 4) {
                        //             if (fans.nickname) {
                        //                 $("#number" + num + " p").html(fans.nickname);
                        //             }
                        //             if (fans.headimgurl) {
                        //                 $("#number" + num + " img").attr('src', fans.headimgurl);
                        //             }
                        //             $("#number" + num + " .mxd-red-text").html(fans.score);
                        //             $("#number" + num).removeClass('hide');
                        //         } else {
                        //
                        //             var headimgurl = fans.headimgurl ? fans.headimgurl : 'http://static.94mxd.com/images/wx_noheadimg.png';
                        //             var nickname = fans.nickname ? fans.nickname : '';
                        //             var talent = '<li class="talent-list">' +
                        //                 '<div class="author">' +
                        //                 '<span class="num">' + num + '</span>' +
                        //                 '<img src="' + headimgurl + '">' +
                        //                 '<div clsss="talent-me-r">' +
                        //                 '<p class="nickName">' + nickname + '</p>' +
                        //                 '<p class="rankInfo rankDistriInfo"><span>累计邀请<span class="rankNum">'+ fans.score +'</span>个好友加入</span></p>' +
                        //                 '</div>'+
                        //                 '</div>' +
                        //                 '</li>';
                        //             $("#talentList").append(talent);
                        //         }
                        //     }
                        // }
                        if (rankingInfo) {
                            for (var i = 0; i < rankingInfo.list.length; i++) {
                                var fans = rankingInfo.list[i];
                                var num = fans.ranking;
                                if (num < 4) {
                                    var headimgurl = fans.headimgurl ? fans.headimgurl : 'https://static.94mxd.com.cn/images/wx_noheadimg.png';
                                    var nickname = fans.nickname ? fans.nickname : '';
                                    var talent = '<li class="talent-list rankList">' +
                                        '<div class="author">' +
                                        '<span class="num">' + num + '</span>' +
                                        '<i class="rankNumber number' + num + '"></i>' +
                                        '<img src="' + headimgurl + '">' +
                                        '<div class="talent-me-r">' +
                                        '<p class="nickName">' + nickname + '</p>' +
                                        '<p class="rankInfo"><span>累计邀请<span class="rankNum">' + fans.score + '</span>个好友加入</span></p>' +
                                        '</div>' +
                                        '</div>' +
                                        '</li>';
                                    $("#talentList").append(talent);
                                } else {
                                    var headimgurl = fans.headimgurl ? fans.headimgurl : 'https://static.94mxd.com.cn/images/wx_noheadimg.png';
                                    var nickname = fans.nickname ? fans.nickname : '';
                                    var talent = '<li class="talent-list">' +
                                        '<div class="author">' +
                                        '<span class="num">' + num + '</span>' +
                                        '<img src="' + headimgurl + '">' +
                                        '<div class="talent-me-r">' +
                                        '<p class="nickName">' + nickname + '</p>' +
                                        '<p class="rankInfo"><span>累计邀请<span class="rankNum">' + fans.score + '</span>个好友加入</span></p>' +
                                        '</div>' +
                                        '</div>' +
                                        '</li>';
                                    $("#talentList").append(talent);
                                }
                            }
                        }

                    } else {
                        $("#rankEmpty").removeClass('hide')
                        $("#distriDataNull").removeClass('hide')
                        $("#rankingWrap").addClass('hide')
                        $("#container").addClass('rankDistri')
                        $("#distriData").addClass('hide')
                    }
                    // if (pageNum > 1) {
                    //     rankScroll.finishPullUp();
                    //     rankScroll.refresh();
                    // }
                },
                error: function () {
                    // if (pageNum > 1) {
                    //     rankScroll.finishPullUp();
                    //     rankScroll.refresh();
                    // }
                    $("#rankEmpty").removeClass('hide')
                    $("#distriDataNull").removeClass('hide')
                    $("#rankingWrap").addClass('hide')
                    $("#container").addClass('rankDistri')
                    $("#distriData").addClass('hide')
                }
            });


            $.ajax({
                url: baseUrl + '/mxd/mdistri/pranking?token=' + token,
                type: 'GET',
                timeout: 5000,
                success: function (data) {
                    data = JSON.parse(data);
                    if (data.respCode == 0) {
                        var result = data.respMsg;
                        var current = result
                        // var current = result.current;
                        if (current) {
                            if (current.headimgurl) {
                                $('.rank-me .author img').attr('src', current.headimgurl);
                            }
                            if (current.nickname) {
                                $('.rank-me  .talent-me-r .nickName').html(current.nickname);
                            }
                            $('.rank-me .author .num').html(current.ranking);
                            $('.rank-me .talent-me-r .rankNum').html(current.score);
                        }

                        if (pageNum == 1) {
                            if (current) {
                                $('#rankMe').removeClass('hasNoInfo');
                                $('#navMyFans').parent().removeClass('hasNoInfo');
                                $('.nav').removeClass('navTwo')
                            } else {
                                $('#rankMe').addClass('hasNoInfo');
                                $('#navMyFans').parent().addClass('hasNoInfo');
                                $('.nav').addClass('navTwo')
                            }
                        }


                    }
                }
            })
            // }
        }

        function loadFansSupportInfo() {
            var tp = ranking.getArgs('tp');
            if (tp) {
                $.ajax({
                    url: baseUrl + '/mxd/taskposter/fanssupportinfo?tp=' + tp,
                    type: 'GET',
                    success: function (data) {
                        data = JSON.parse(data);
                        if (data.respCode == 0) {
                            if (data.respMsg.inBlacklist) {

                                $('#navMyFans').parent().remove()
                                $('.nav').addClass('navTwo')
                                $('#rankMe').addClass('hideBlack')
                            }
                            var result = data.respMsg;
                            var goal = result.goal;
                            var goal2 = result.goal2;
                            var goal3 = result.goal3;
                            var totalSupport = result.totalSupport;
                            $('.complete-info .headimg img').attr('src', result.headimgurl);
                            $('.complete-info .fans p').html(result.nickname);
                            $('.complete-num .num').html(totalSupport);

                            if (data.inBlackList) {
                                $('#navMyFans').parent().hide();
                                $('.nav').addClass('navTwo');
                                $('#rankMe').addClass('hideBlack');
                            }

                            if (goal > 0 && goal2 == 0 && goal3 == 0) {
                                //只有一阶任务
                                $(".complete-progress .lv.right").removeClass('hide');
                                $(".complete-progress .lv.right span").html('LV1');
                                $(".complete-progress .lv.right p").html(goal);
                                if (totalSupport < goal) {
                                    //未完成任务
                                    var rate = totalSupport * 100 / goal;
                                    $(".progress").css('width', rate + '%')
                                } else {
                                    //完成一阶任务
                                    $(".progress").css('width', '100%')
                                    $(".complete-progress .lv.right span").addClass('active');
                                    $(".headimg span").html('LV1');
                                    $(".headimg span").show();
                                }
                            } else if (goal > 0 && goal2 > 0 && goal3 == 0) {
                                //有二阶任务
                                $(".complete-progress .lv.left2").removeClass('hide');
                                $(".complete-progress .lv.left2 span").html('LV1');
                                $(".complete-progress .lv.left2 p").html(goal);
                                $(".complete-progress .lv.right").removeClass('hide');
                                $(".complete-progress .lv.right span").html('LV2');
                                $(".complete-progress .lv.right p").html(goal2);
                                if (totalSupport < goal) {
                                    //一个都没完成
                                    var rate = (totalSupport / goal) * 40;
                                    $(".progress").css('width', rate + '%')
                                } else if (totalSupport >= goal && totalSupport < goal2) {
                                    var rate = ((totalSupport - goal) / (goal2 - goal)) * 60 + 40;
                                    $(".progress").css('width', rate + '%')
                                    $(".complete-progress .lv.left2 span").addClass('active');
                                    $(".headimg span").html('LV1');
                                    $(".headimg span").show();
                                } else {
                                    //完成二阶任务
                                    $(".progress").css('width', '100%')
                                    $(".complete-progress .lv.left2 span").addClass('active');
                                    $(".complete-progress .lv.right span").addClass('active');
                                    $(".headimg span").html('LV2');
                                    $(".headimg span").show();
                                }
                            } else if (goal > 0 && goal2 > 0 && goal3 > 0) {
                                //三阶任务
                                $(".complete-progress .lv.left").removeClass('hide');
                                $(".complete-progress .lv.left span").html('LV1');
                                $(".complete-progress .lv.left p").html(goal);
                                $(".complete-progress .lv.middle").removeClass('hide');
                                $(".complete-progress .lv.middle span").html('LV2');
                                $(".complete-progress .lv.middle p").html(goal2);
                                $(".complete-progress .lv.right").removeClass('hide');
                                $(".complete-progress .lv.right span").html('LV3');
                                $(".complete-progress .lv.right p").html(goal3);
                                if (totalSupport < goal) {
                                    //一个都没完成
                                    var rate = (totalSupport / goal) * 15;
                                    $(".progress").css('width', rate + '%')
                                } else if (totalSupport >= goal && totalSupport < goal2) {
                                    var rate = ((totalSupport - goal) / (goal2 - goal)) * 35 + 17;
                                    $(".progress").css('width', rate + '%')
                                    $(".complete-progress .lv.left span").addClass('active');
                                    $(".headimg span").html('LV1');
                                    $(".headimg span").show();
                                } else if (totalSupport >= goal2 && totalSupport < goal3) {
                                    //完成二阶任务
                                    var rate = ((totalSupport - goal2) / (goal3 - goal2)) * 35 + 52;
                                    $(".progress").css('width', rate + '%');
                                    $(".complete-progress .lv.left span").addClass('active');
                                    $(".complete-progress .lv.middle span").addClass('active');
                                    $(".headimg span").html('LV2');
                                    $(".headimg span").show();
                                } else {
                                    //完成三阶
                                    $(".progress").css('width', '100%');
                                    $(".complete-progress .lv.left span").addClass('active');
                                    $(".complete-progress .lv.middle span").addClass('active');
                                    $(".complete-progress .lv.right span").addClass('active');
                                    $(".headimg span").html('LV3');
                                    $(".headimg span").show();
                                }
                            }
                        }
                    },
                    error: function () {
                    }
                });
            }
        }

        function loadFansSupport(pageNum) {
            var tp = ranking.getArgs('tp');
            var myFansScroll = ranking.myFansScroll;
            if (tp) {
                $.ajax({
                    url: baseUrl + '/mxd/taskposter/fanssupport/1.1?tp=' + tp + '&pageNum=' + pageNum,
                    type: 'GET',
                    success: function (data) {
                        data = JSON.parse(data);
                        if (data.respCode == 0) {
                            var result = data.respMsg;
                            var list = result.list;
                            var pagInfo = result.pagInfo;
                            if (result.pagInfo) {
                                if (pagInfo.total == 0) {
                                    $('.no-support').show();
                                } else {
                                    for (var i = 0; i < list.length; i++) {
                                        var support = list[i];
                                        var help = '';
                                        if (support.help == 1) {
                                            help = '助力成功'
                                        } else if (support.help == 0 && support.reason == 1) {
                                            help = '已取关，助力无效'
                                        } else {
                                            help = '老粉丝，助力无效'
                                        }
                                        var html = '<div class="talent-list">\n' +
                                            '                        <div class="author">\n' +
                                            '                            <img src="' + support.headimgurl + '">\n' +
                                            '                            <p>' + support.nickname + '</p>\n' +
                                            '                        </div>\n' +
                                            '                        <div class="talent-me-r">\n' +
                                            '                            <p>' + help + '</p>\n' +
                                            '                            <span>' + getPreDate(support.supportTime) + '</span>\n' +
                                            '                        </div>\n' +
                                            '                    </div>';
                                        $('.support-list').append(html);
                                    }
                                }
                            }

                        }
                        if (pageNum > 1) {
                            myFansScroll.finishPullUp();
                            myFansScroll.refresh();
                        }
                    },
                    error: function () {
                        if (pageNum > 1) {
                            myFansScroll.finishPullUp();
                            myFansScroll.refresh();
                        }
                    }
                });
            }
        }

        function initRankScroll() {
            const options = {
                scrollY: true,
                mouseWheel: true,
                scrollbar: {
                    fade: true
                },
                click: true,
                probeType: 3,
                pullUpLoad: {
                    threshold: -20
                }
            };
            ranking.rankScroll = new BScroll("#rankContainer", options);
            // ranking.rankScroll.on('pullingUp', function () {
            //         loadRanking(++ranking.pageNum);
            // })
        }

        function initMyFansScroll() {
            const options = {
                scrollY: true,
                mouseWheel: true,
                scrollbar: {
                    fade: true
                },
                click: true,
                probeType: 3,
                pullUpLoad: {
                    threshold: -20
                }
            };
            // ranking.myFansScroll = new BScroll("#myFansContainer", options);
            // ranking.myFansScroll.on('pullingUp', function () {
            //     loadFansSupport(++ranking.supportPageNum);
            // })
        }

        ranking.initZF = function () {
            const options = {
                scrollY: true,
                mouseWheel: true,
                scrollbar: {
                    fade: true
                }
            };
            // ranking.zfScroll = new BScroll("#zfContainer", options);
            $.ajax({
                url: '/mxd/promption/fanslist',
                type: 'GET',
                success: function (data) {
                    data = JSON.parse(data);
                    if (data.respCode == 0) {
                        var fanslist = data.respMsg.list;
                        var getfan = '';
                        if(fanslist.length>0){
                            for (var i = fanslist.length - 1; i > fanslist.length - 7; i--) {
                                getfan += ranking.getZfFan(fanslist[i]);
                            }
                        }

                        $("#zfList").html(getfan);
                        ranking.zfFanList = fanslist;
                        ranking.count = fanslist.length - 7;
                        ranking.playZfFanList();
                    }
                },
                error: function () {
                }
            });
        }

        ranking.playZfFanList = function () {
            if (ranking.count < 0) {
                ranking.count = ranking.zfFanList.length - 1;
            }
            if(ranking.count>=0){
                $("#zfList").prepend(ranking.getZfFan(ranking.zfFanList[ranking.count--]));
            }

            $("#zfList .getfan:last").remove();
            var sec = Math.round(Math.random() * 5) + 5;
            // console.log(sec)
            setTimeout(ranking.playZfFanList, sec * 1000)
        };

        ranking.getZfFan = function (fan) {
            return '<div class="getfan">\n' +
                '                        <div class="author">\n' +
                '                            <img src="' + fan.headimgurl + '">\n' +
                '                            <div class="right">\n' +
                '                                <p>' + fan.nickname + '</p>\n' +
                '                            </div>\n' +
                '                        </div>\n' +
                '                        <p class="get-text">已领取</p>\n' +
                '                    </div>';
        }

        ranking.initNav = function (type) {
            if (type == 1) {
                //有任务宝会员
                $(".nav").removeClass('hide');
                $(".nav").addClass('nav-2');
                $('#navZf').addClass('hide');
                $('.nav').addClass('isVip');
                $("#navRank").click(function () {
                    $("#rankContainer").removeClass('hide');
                    $("#navRank").addClass('active');
                    $("#rankMe").removeClass('hide');
                    $("#myFansContainer").addClass('hide');
                    $("#navMyFans").removeClass('active');
                    ranking.rankScroll.refresh();
                });
                $("#navMyFans").click(function () {
                    $("#rankContainer").addClass('hide');
                    $("#navRank").removeClass('active');
                    $("#rankMe").addClass('hide');
                    $("#myFansContainer").removeClass('hide');
                    $("#navMyFans").addClass('active');
                    ranking.myFansScroll.refresh();
                });
            } else {
                $(".nav").removeClass('hide');
                $('.nav').removeClass('isVip');
                $('#navZf').removeClass('hide');
                $("#navRank").click(function () {
                    $("#rankContainer").removeClass('hide');
                    $("#navRank").addClass('active');
                    $("#rankMe").removeClass('hide');
                    $("#myFansContainer").addClass('hide');
                    $("#navMyFans").removeClass('active');
                    $("#zfContainer").addClass('hide');
                    $("#navZf").removeClass('active');
                    ranking.rankScroll.refresh();
                })
                $("#navMyFans").click(function () {
                    $("#rankContainer").addClass('hide');
                    $("#navRank").removeClass('active');
                    $("#rankMe").addClass('hide');
                    $("#myFansContainer").removeClass('hide');
                    $("#navMyFans").addClass('active');
                    $("#zfContainer").addClass('hide')
                    $("#navZf").removeClass('active');
                    ranking.myFansScroll.refresh();
                })
                $("#navZf").click(function () {
                    $("#rankContainer").addClass('hide');
                    $("#navRank").removeClass('active');
                    $("#rankMe").addClass('hide');
                    $("#myFansContainer").addClass('hide');
                    $("#navMyFans").removeClass('active');
                    $("#zfContainer").removeClass('hide')
                    $("#navZf").addClass('active');
                    ranking.zfScroll.refresh();
                })
            }
        }

        function getPreDate(datetime) {
            var date = new Date(datetime);
            var now = new Date();
            var day = 24 * 60 * 60 * 1000;
            var dd = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
            var date0 = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() + day;
            var date1 = date0 + day;
            var date2 = date1 + day;
            var msg = '';
            if (datetime >= dd && datetime < date2) {
                var time = date.format('hh点mm分');
                if (datetime < date0) {
                    msg = '今天' + time
                } else if (datetime < date1) {
                    msg = '明天' + time
                } else if (datetime < date2) {
                    msg = '后天' + time
                }
            } else {
                msg = date.format('yyyy-MM-dd hh:mm')
            }
            return msg;
        }
    }

)(window.DisRanking = {});