import {Toast, Modal} from "antd-mobile/lib/index";
import Api from "./Api"
import {Path} from "../path"

let flag = true

class Util {
    static regPhone(data) {
        let reg = /^1\d{10}$/
        if (!reg.test(data)) {
            return false
        } else {
            return true
        }
    }

    static regIdCard(data) {
        let reg = /^(\d{15}$|^\d{18}$|^\d{17}(\d|X|x))$/
        if (!reg.test(data)) {
            return false
        } else {
            return true
        }
    }

    static regEmail(email) {
        var emailReg = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,6}){1,3})$/;
        if (!emailReg.test(email)) {
            return false;
        }
        return true;
    }

    static getArgs = function (name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURIComponent(r[2]);
        return null;
    }

    static isEmpty(val) {
        // val = val.trim();
        if (val == null || String(val) == '' || typeof val == 'undefined') {
            return true;
        }
        return false;
    }

    static isPhoneNo(phoneNo) {
        let phoneNoReg = /^1[3456789]\d{9}$/;
        return phoneNoReg.test(phoneNo);
    }

    static isEmail(email) {
        let emailReg = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,6}){1,3})$/;
        return emailReg.test(email);
    }

    static isCard(str) {
        let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        return reg.test(str);
    }

    static onBridgeReady(tradeno, props, isGroupbuy, isGhead, success, error) {

        Api.getGroupBuyWxpay(tradeno, (data) => {
            if (data.respCode == 0) {
                let msg = data.respMsg
                let that = this
                // alert(JSON.stringify(data.respMsg))
                // alert("msg="+JSON.stringify(msg))
                // if(typeof WeixinJSBridge != "undefined"){
                window.WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', msg,
                    function (res) {
                        Util.getPaySuccess(tradeno, props, isGroupbuy)
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            flag = true
                            Toast.loading("支付完成，跳转中...", 1000, null, true);
                            // 使用以上方式判断前端返回,微信团队郑重提示：
                            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                            Toast.info("支付失败", 3, null, false)
                            flag = false
                            success();
                        } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                            // this.setState({
                            //     payFlag:false
                            // })
                            // this.payFlag = false
                            flag = false
                            success();
                        }

                    });
                // }

            } else if (data.respCode == 1090) {
                // Modal.alert('提示', '订单支付超时', [
                //     {
                //         text: '取消',
                //         onPress: () => {flag = false},
                //         style: 'cancel',
                //     },
                //     {
                //         text: '我要开团',
                //         style: {background: "#FF4A5B", color: "#fff"},
                //         onPress: () => {
                //             props.history.push(Path.gotoOpen(),{groupId:0,isGroupbuy:1,isGHead:1})
                //         }
                //     },
                // ]);
                error();
            }
        }, (error) => {
            flag = false
            Toast.info("支付系统错误", 3, null, false)
        })

    }

    static getPaySuccess(tradeno, props, isGroupbuy, isGhead) {
        let paySuccessTime = setTimeout(() => {
            // alert('付款成功'+tradeno)
            Api.getGroupBuyPayReault(tradeno, (data) => {
                // alert(JSON.stringify(data))
                if (data.respCode == 0) {
                    clearInterval(paySuccessTime)
                    // alert(data.respCode)
                    Api.groupBuyPaysuccess("tradeno", tradeno, (data) => {
                        // alert(JSON.stringify(data))
                        if (data.respCode == 0) {
                            let msg = data.respMsg
                            if (msg.payOkType == 3) {
                                window.location.href = msg.content
                            } else if (msg.payOkType == 1 || msg.payOkType == 2) {
                                // window.location.href = window.location.href.split("open")[0]+'paySuccess'
                                // alert(tradeno)

                                let urlHref = window.sessionStorage.getItem("urlHref")
                                window.sessionStorage.setItem("urlHrefFlag", true)
                                Toast.hide()
                                window.location.href = urlHref + Path.gotoSuccessPay() + "?isGroupbuy=" + isGroupbuy + "&isGhead=" + isGhead + '&tradeno=' + tradeno

                                // window.history.replaceState(null,'',urlHref+Path.gotoSuccessPay()+"?isGroupbuy="+isGroupbuy+"&isGhead="+isGhead+'&tradeno='+tradeno)

                                // props.history.replace(Path.gotoSuccessPay(), {
                                //     isGroupbuy: isGroupbuy,
                                //     isGhead: isGhead,
                                //     tradeno: tradeno
                                // })
                            }
                        } else {
                            Toast.info("支付跳转系统错误", 3, null, false)
                        }
                    }, () => {
                        Toast.info("支付跳转系统错误", 3, null, false)
                    })

                } else {
                    Util.getPaySuccess(tradeno, props, isGroupbuy, isGhead)
                }
            }, (error) => {
                // alert(JSON.stringify(error))
                // Util.getPaySuccess(tradeno,props,isGroupbuy,isGhead)
                Toast.info("支付成功系统错误", 3, null, false)
            })
        }, 500)

    }

    static OrderStatus = {
        //拼团中
        GROUP_STATUS_ING: 2,
        //拼团失败
        GROUP_STATUS_FAIL: 0,
        //等待创建
        GROUP_STATUS_WAIT: -1,
        //拼团成功
        GROUP_STATUS_SUCCESS: 1,
        //不在拼团中
        GROUP_STATUS_NO: -2,

        //订单取消
        ORDER_STATUS_CANCEL: -3,
        //订单退款
        ORDER_STATUS_REFUND: -2,
        //订单待退款
        ORDER_STATUS_WAITREFUND: -1,
        //订单待支付
        ORDER_STATUS_WAITPAY: 0,
        //待拼团结果
        ORDER_STATUS_WAITGROUP: 1,
        //待发货
        ORDER_STATUS_WAITSEND: 2,
        //已发货
        ORDER_STATUS_HASSEND: 3,
        //已完成
        ORDER_STATUS_COMPLETE: 4,
    }
    static OrderDistriStatus = {
        //拼团中
        GROUP_STATUS_ING: 2,
        //拼团失败
        GROUP_STATUS_FAIL: 0,
        //等待创建
        GROUP_STATUS_WAIT: -1,
        //拼团成功
        GROUP_STATUS_SUCCESS: 1,

        //订单取消
        ORDER_STATUS_CANCEL: -3,
        //订单退款
        ORDER_STATUS_REFUND: -2,
        //订单待退款
        ORDER_STATUS_WAITREFUND: -1,
        //订单待支付
        ORDER_STATUS_WAITPAY: 0,
        //待拼团结果
        // ORDER_STATUS_WAITGROUP : 1,
        //待发货
        ORDER_STATUS_WAITSEND: 1,
        //已发货
        ORDER_STATUS_HASSEND: 3,
        //已完成
        ORDER_STATUS_COMPLETE: 4,
    }

    static checkCode = {
        2001: "商品已售完",
        2002: "超过团购次数",
        2003: "当前团已经拼团成功",
        2004: "当前团拼团失败",
        2005: "已经在团中",
        2006: "该团拼团人数较多，请稍后再试",
    }

    static checkUrl = {
        goods: ["/mshop/goods", "/mshop/mine/orderDetail", "/mshop/mine/order", "/mshop/mine/index",
            "/mshop/mine/address", "/mshop/mine/createAddress", "/mshop/mine/accountSet", "/mshop/mine/changePhone",
            "/mshop/mine/mineMsg", "/mshop/mine/changeName", "/mshop/mine/changeVXName", '/mshop/rank', '/mshop/receive/fill',
            '/mshop/receive/link',],
        distri: ["/mshop/distri/goods", "/mshop/distri/success", "/mshop/distri/rank/", "/mshop/distri/cash/index", "/mshop/distri/cash/money",
            "/mshop/distri/openPay", "/mshop/distri/order/list", "/mshop/distri/order/detail"],
        groupbuy: ["/mshop/groupbuy/goods/", "/mshop/groupbuy/open", '/mshop/groupbuy/paySuccess', '/mshop/groupbuy/success',
            '/mshop/groupbuy/order/list', '/mshop/groupbuy/order/detail'],
    }

    static Flag = flag

    static getUrlArg(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");

        var r = window.location.search.substr(1).match(reg);

        if (r != null) return decodeURIComponent(r[2]);
        return null;
    }

    static getAntdDistrictZh(code, antdDistrict) {
        let addZh = [];
        let pCode = code[0];
        let cCode = code[1];
        let qCode = undefined;
        if (code.length > 2) {
            qCode = code[2];
            for (let i = 0; i < antdDistrict.length; i++) {
                let pData = antdDistrict[i];
                if (pData.value == pCode) {
                    addZh.push(pData.label);
                }
                let cArr = pData.children;
                for (let m = 0; m < cArr.length; m++) {
                    let cData = cArr[m];
                    if (cData.value == cCode) {
                        addZh.push(cData.label);
                    }
                    let qArr = cData.children;
                    for (let k = 0; k < qArr.length; k++) {
                        let qData = qArr[k];
                        if (qData.value == qCode) {
                            addZh.push(qData.label);
                            return addZh[0] + ' ' + addZh[1] + ' ' + addZh[2];
                        }
                    }
                }
            }
        } else {
            for (let i = 0; i < antdDistrict.length; i++) {
                let pData = antdDistrict[i];
                if (pData.value == pCode) {
                    addZh.push(pData.label);
                }
                let cArr = pData.children;
                for (let m = 0; m < cArr.length; m++) {
                    let cData = cArr[m];
                    if (cData.value == cCode) {
                        addZh.push(cData.label);
                        return addZh[0] + ' ' + addZh[1];
                    }
                }
            }
        }
    }
}

export default Util































