class Path {

    static getOrderDetail() {
        return "/mshop/mine/myorder"
    }

    static getOrder() {
        return "/mshop/mine/order"
    }

    static getMine() {
        return "/mshop/mine/index"
    }

    static getAddress() {
        return "/mshop/mine/address"
    }

    static getCreateAddress() {
        return "/mshop/mine/createAddress"
    }

    static getAccountSet() {
        return "/mshop/mine/accountSet"
    }

    static getChangePhone() {
        return "/mshop/mine/changePhone"
    }

    static getMineMsg() {
        return "/mshop/mine/mineMsg"
    }

    static getChangeName() {
        return "/mshop/mine/changeName"
    }

    static getChangeVXName() {
        return "/mshop/mine/changeVXName"
    }


    static getRank() {
        return '/mshop/rank/' + new Date().getTime();
    }

    static getReceiveFill() {
        return '/mshop/receive/fillinfo'
    }

    static getReceiveLink() {
        return '/mshop/receive/link'
    }


    static getDistriRank() {
        return '/mshop/distri/rank'
    }

    static getSuccessLink() {
        return '/mshop/distri/success'
    }

    static getCashIndex() {
        return '/mshop/distri/cash/index'
    }

    static getCashMoney() {
        return '/mshop/distri/cash/money'
    }

    static getDistriOrderList() {
        return '/mshop/distri/order/list'
    }

    static getDistriOrderDetail() {
        return '/mshop/distri/order/detail'
    }

    static getOpenPay() {
        return '/mshop/distri/openPay'
    }


    static gotoGoodsBuy(id) {
        return '/mshop/groupbuy/goods/' + id
    }

    static gotoOpen(tradeno) {
        return '/mshop/groupbuy/open'
    }

    static gotoSuccessPay() {
        return '/mshop/groupbuy/paySuccess'
    }

    static gotoSuccessBuy() {
        return '/mshop/groupbuy/success'
    }

    static gotoOrderBuy() {
        return '/mshop/groupbuy/order/list'
    }

    static gotoOrderDetailBuy() {
        return '/mshop/groupbuy/order/detail'
    }

    static gotoLogisticsResult() {
        return '/mshop/logistics/result/'
    }


}

export {Path}













