import React, {Component} from 'react';
import Loadable from 'react-loadable';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Loading from '../loading'
class Distri extends Component {
    render() {
        return (
            <div>
                <Route exact path={this.props.match.url + "/goods/:id"} component={
                    Loadable({
                        loader: () => import('./distri_show'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/test"} component={
                    Loadable({
                        loader: () => import('./test'),
                        loading: Loading
                    })
                }/>
                <Route path={this.props.match.url + "/open"} component={
                    Loadable({
                        loader: () => import('./groupbuy_open'),
                        loading: Loading
                    })
                }/>

                <Route path={this.props.match.url + "/paySuccess"} component={
                    Loadable({
                        loader: () => import('./successPay/success'),
                        loading: Loading
                    })
                }/>

                <Route path={this.props.match.url + "/success"} component={
                    Loadable({
                        loader: () => import('./successBuy/index'),
                        loading: Loading
                    })
                }/>

                <Route path={this.props.match.url + "/order/list"} component={
                    Loadable({
                        loader: () => import('./order/order_index'),
                        loading: Loading
                    })
                }/>

                <Route path={this.props.match.url + "/order/detail"} component={
                    Loadable({
                        loader: () => import('./order/order_detail'),
                        loading: Loading
                    })
                }/>


            </div>
        );
    }
}

export default Distri;