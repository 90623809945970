import React, {Component} from 'react'
// import './style/rank.less'
import './ranking'
import Qrcode from '../../image/rank/zf_qrcode.jpg'
// import ShareModal from '../distribution/shareMiddle'
import {Path} from '../../path'
import Technology from '../public/technology'
import ChangeTitle from "../public/changeTitle/changeTitle"
import SharePic from '../public/shareDistriPic/sharePic'
import Pic from "../../image/yishan.jpg"
import Empty from "../../image/empty.png"
import Api from "../../util/Api";
import {Toast} from "antd-mobile/lib/index";

class Rank extends Component {
    constructor(props){
        super(props);
        this.shareFriendsClose=this.shareFriendsClose.bind(this);
        this.changeImg=this.changeImg.bind(this);
        this.showShareModal=this.showShareModal.bind(this);

        this.state={
            shareFriends:false,
            fansId:null,
            openImgFlag:false
        }
    }

    componentWillMount() {
        // window.Ranking.init();
    }

    componentDidMount() {
        window.DisRanking.init();
        document.getElementById('root').className='rankContainerDistri';
        this.getGoodsData()
        // if(!this.props.location.search){
        //     this.props.history.push(Path.getMine())
        // }
    }

    componentWillUnmount() {
        document.getElementById('root').className='';
        // window.sessionStorage.removeItem("token")
    }

    shareFriendsClose(){
        this.setState({
            shareFriends:false
        })
    }
    showShareModal(){
        this.setState({
            openImgFlag:true
        })
    }

    changeImg(){
        this.setState({
            openImgFlag:false
        })
    }

    getGoodsData(){
        Api.getGoodsData((res)=>{
            // alert(JSON.stringify(res))
            if(res.respCode == 0){
                let goods = res.respMsg.goods

                this.setState({
                    goodsData:goods
                })
                window.sessionStorage.setItem("royalty",res.respMsg.goods?res.respMsg.goods.royalty:0)
            }
        },(error)=>{
            Toast.info("商品系统错误",3,null,false)
        })
        // this.setState({
        //     goodsData:data
        // })
    }


    render(){
        let clientH = parseInt((document.documentElement.clientHeight || document.body.clientHeight)*0.7)
        return(
            <div>
                <div id="container" className='rankWrapper rankDistriWrapper'>
                    <ChangeTitle title="收益排行"></ChangeTitle>
                    {/*<ShareModal close={this.shareFriendsClose} className={this.state.shareFriends?"mxd-display-block":"mxd-display-none"}/>*/}
                    <SharePic
                        showHelpShare={this.state.openImgFlag}
                        closePoster={this.changeImg}
                        goodsData={this.state.goodsData}
                    ></SharePic>
                    <div id="rankContainer" className="hide">
                        <div>
                            <div className="prizeGoodsImg" id={'renturnTop'} style={{maxHeight:clientH>"5rem"?"5rem":clientH+"px"}}>
                                <img id='rankImg' className="prizeGoodsPic"/>
                            </div>
                            <div className="about-ranking about-distri-ranking" style={{minHeight:'(calc(100vh - (clientH>"5rem"?"5rem":clientH+"px")-1.2rem))'}}>
                                <ul className="ranking-wrap" id='rankingWrap'>
                                    {/*<li className="ranking-header">*/}
                                        {/*<ul>*/}
                                            {/*<li className="card">*/}
                                                {/*<ul className="card-people card-people-distri">*/}
                                                    {/*<li id="number2" className="number2 rank-distri-li hide">*/}
                                                        {/*<div className="card-people-img" align="center">*/}
                                                            {/*<img src="http://static.94mxd.com/images/wx_noheadimg.png" />*/}
                                                        {/*</div>*/}
                                                        {/*<p></p>*/}
                                                        {/*<span>邀请&nbsp;<span className={'mxd-red-text'}>0</span>&nbsp;好友</span>*/}
                                                    {/*</li>*/}
                                                    {/*<li id="number1" className="number1 rank-distri-li hide">*/}
                                                        {/*<div className="path1"></div>*/}
                                                        {/*<div className="path2"></div>*/}
                                                        {/*<div className="card-people-img" align="center">*/}
                                                            {/*<img src="http://static.94mxd.com/images/wx_noheadimg.png" />*/}
                                                        {/*</div>*/}
                                                        {/*<p></p>*/}
                                                        {/*<span>邀请&nbsp;<span className={'mxd-red-text'}>0</span>&nbsp;好友</span>*/}
                                                    {/*</li>*/}
                                                    {/*<li id="number3" className="number2 number3 rank-distri-li hide">*/}
                                                        {/*<div className="card-people-img" align="center">*/}
                                                            {/*<img src="http://static.94mxd.com/images/wx_noheadimg.png" />*/}
                                                        {/*</div>*/}
                                                        {/*<p></p>*/}
                                                        {/*<span>邀请&nbsp;<span className={'mxd-red-text'}>0</span>&nbsp;好友</span>*/}
                                                    {/*</li>*/}
                                                {/*</ul>*/}
                                            {/*</li>*/}
                                        {/*</ul>*/}
                                    {/*</li>*/}
                                    <li>
                                        <div id="rankMe" className="rank-me">
                                            <div className="talent-list borderNone">
                                                <div className="author">
                                                    <span className='num'>
                                                        <span>0</span>
                                                        {/*<p>我的排名</p>*/}
                                                    </span>
                                                    <img src="https://static.94mxd.com.cn/images/wx_noheadimg.png" />
                                                </div>
                                                <div className="talent-me-r">
                                                    <p className='nickName'>夏日</p>
                                                    <p className='rankInfo'><span>累计邀请<span className='rankNum'>0</span>个好友加入</span></p>
                                                </div>
                                                {/*<div className='invite' onClick={this.showShareModal.bind(this)}>*/}
                                                {/*去邀请*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </li>
                                    <li className={'mxd-body-trigger'}></li>
                                    <li className="talent-rank">
                                        <ul id="talentList">
                                        </ul>
                                    </li>
                                </ul>
                                {/*<div className="pullupload distriRanking">*/}
                                    {/*<p></p>*/}
                                {/*</div>*/}
                                <div id='rankEmpty' className='hide'>
                                    <img src={Empty}/>
                                    <p>暂无数据</p>
                                </div>

                                <div id={'distriData'}>
                                    <Technology></Technology>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id={'distriDataNull'} className={'hide'}>
                        <Technology></Technology>
                    </div>

                    {/*<div id="myFansContainer" className="hide">*/}
                    {/*<div className='myFansWrapper'>*/}
                    {/*<div className="header-bg">*/}
                    {/*</div>*/}
                    {/*<div className="about-fans-ranking">*/}
                    {/*<div className="complete-info">*/}
                    {/*<div className="fans">*/}
                    {/*<div className="headimg">*/}
                    {/*<img />*/}
                    {/*<span></span>*/}
                    {/*</div>*/}
                    {/*<p></p>*/}
                    {/*</div>*/}
                    {/*<div className="complete-num">*/}
                    {/*<span>你当前获得助力</span>*/}
                    {/*<span className="num">0</span>*/}
                    {/*</div>*/}
                    {/*<div className="complete-progress">*/}
                    {/*<div className="progress-bar"></div>*/}
                    {/*<div className="progress"></div>*/}
                    {/*<div className="lv left hide">*/}
                    {/*<span></span>*/}
                    {/*<p></p>*/}
                    {/*</div>*/}
                    {/*<div className="lv left2 hide">*/}
                    {/*<span></span>*/}
                    {/*<p></p>*/}
                    {/*</div>*/}
                    {/*<div className="lv middle hide">*/}
                    {/*<span></span>*/}
                    {/*<p></p>*/}
                    {/*</div>*/}
                    {/*<div className="lv right hide">*/}
                    {/*<span></span>*/}
                    {/*<p></p>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className="support-list">*/}
                    {/*<p className="no-support">暂时还没有好友支持你哦</p>*/}
                    {/*</div>*/}
                    {/*<Technology></Technology>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*<div id="zfContainer" className="hide">*/}
                    {/*<div className="about-zf">*/}
                    {/*<div className="zf-space-top"></div>*/}
                    {/*<div className="zf-logo">*/}
                    {/*</div>*/}
                    {/*<div className="zf-slogan"></div>*/}
                    {/*<div className="zf-pronum">*/}
                    {/*限送<span>200</span>个*/}
                    {/*</div>*/}
                    {/*<div className="zf-qrcode">*/}
                    {/*<img src={Qrcode} />*/}
                    {/*</div>*/}
                    {/*<p className="zf-qrcode-des">长按二维码领取</p>*/}
                    {/*<div className="zf-getfanlist">*/}
                    {/*<div id="zfList">*/}

                    {/*</div>*/}
                    {/*</div>*/}
                    {/*/!*<div className="zf-space"></div>*!/*/}
                    {/*<div className="tf bottomCopyRight">*/}
                    {/*<Technology></Technology>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}

                </div>

                <footer className={'rankFooter'} onClick={this.showShareModal}>
                    <span className={'mxd-red-color'}>分享活动赚取奖励金</span>
                </footer>
            </div>
        )
    }
}

export default Rank








