import React, {Component} from 'react'
import {ActivityIndicator} from "antd-mobile"
import "./index.less"



class Loading extends Component {


    render() {
        return (
            <div>
                <div className="mxd-load">
                    <div className="loading-example">
                        {/*<ActivityIndicator*/}
                            {/*text="Loading..."*/}
                        {/*/>*/}
                        <div>
                            <span className={'am-activity-indicator-spinner'}></span>
                            <span className={'ml10'}>Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Loading