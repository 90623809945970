import React, {Component} from 'react'
import {Button, Tabs, Badge, Toast, List, InputItem, Picker, Modal, TextareaItem, DatePicker} from "antd-mobile"
import {Path} from "../../path";
import Util from "../../util/util";
import Api from "../../util/Api"
import ImgIcon from "../../image/goods/icon/001.png"
import Technology from "../public/technology"
import NoPay from "../distribution/component/no_pay"
import ChangeTitle from "../public/changeTitle/changeTitle"
import FormFields from "../public/FormFields";

const Item = List.Item;
let antdDistrict = [];

class OpenPay extends Component {

    constructor(props) {
        super(props);
        // this.onBridgeReady = this.onBridgeReady.bind(this);
        this.getPhone = this.getPhone.bind(this);
        this.getRemark = this.getRemark.bind(this);
        this.getAddress = this.getAddress.bind(this);
        this.getName = this.getName.bind(this);
        this.getDetailMsg = this.getDetailMsg.bind(this);
        this.getData = this.getData.bind(this);
        this.getBridgeReady = this.getBridgeReady.bind(this);
        // this.getPaySuccess = this.getPaySuccess.bind(this);
        this.payMust = this.payMust.bind(this);
        this.parseParmStr = this.parseParmStr.bind(this);
        this.state = {
            pickerValue: [],
            phone: "",
            detailMsg: "",
            name: "",
            visible: false,
            addressId: null,
            preorderData: null,
            tradeno: null,
            remarkData: "",
            readFlag: false,
            partNum: 0,
            submitData: {},
            formFields: {}
        }

        this.payFlag = false
    }

    componentWillMount() {
        if (window.sessionStorage.getItem("urlHrefFlag")) {
            window.sessionStorage.removeItem("urlHrefFlag")
            // window.sessionStorage.setItem("flag",true)
            // this.props.history.goBack()
            window.history.go(-2);
        }
    }

    componentDidMount() {
        // let groupId = this.props.location.state?this.props.location.state.groupId:0,
        //     isGroupbuy = this.props.location.state?this.props.location.state.isGroupbuy:0,
        //     isGHead = this.props.location.state?this.props.location.state.isGHead:0
        // this.groupId = groupId
        // this.isGroupbuy = isGroupbuy
        // this.isGHead = isGHead
        // let data={
        //     "coverImgUrl": ImgIcon,
        //     "price": "20.00",
        //     "gprice": "30.7",
        //     "name": "商品名称",
        //     "gnum": 5,
        //     "payPrice": 20.3,
        //     "needForm": 1,
        //     "formFields": ["phoneno","address","realname"],
        //     "limited":true
        // }
        // this.setState({
        //     preorderData:data
        // })

        let tradeno = this.props.location.state ? this.props.location.state.tradeno : ""
        let token = window.sessionStorage.getItem("token")

        var domain = window.location.href.split('/');
        let href = domain[0] + "//" + domain[1] + domain[2]
        window.sessionStorage.setItem("urlHref", href)

        Toast.hide()

        if (window.location.href.indexOf("tradeno=") >= 0) {
            this.getData()
        } else {
            if (window.sessionStorage.getItem("flag")) {
                this.props.history.goBack()
            } else {
                Api.domainmapQuery((res) => {
                    if (res.respCode == 0) {

                        let url = res.respMsg.domain
                        // let url = "http://localhost:3000"
                        window.location.href = url + "/mshop/distri/openPay?tradeno=" + tradeno + "&token=" + token + "&urlHref=" + href

                        window.sessionStorage.setItem("flag", true)

                    }
                }, () => {
                    Toast.info("更改域名系统错误！", 3, null, false)
                })
            }

        }


    }

    componentWillReceiveProps(nextProps) {

    }

    componentWillUnmount() {
        Toast.hide()
    }

    isJsonString(str) {
        try {
            if (typeof JSON.parse(str) == "object") {
                return true;
            }
        } catch (e) {

        }
        return false;
    }

    getData() {
        let hrefData = this.parseParmStr(window.location.href)
        let tradeno = hrefData.tradeno,
            token = hrefData.token
        window.sessionStorage.setItem("token", token)
        if (tradeno) {
            Api.orderDetailDistri(tradeno, (res) => {
                // alert(JSON.stringify(res))
                if (res.respCode == 0) {
                    // let data = res.respMsg
                    let data = res.respMsg,
                        formContent = JSON.parse(data.formContent),
                        formFields = JSON.parse(data.formFields),
                        submitData = {}
                    for (let i = 0; i < formContent.length; i++) {
                        if (formContent[i].type == 3 || formContent[i].type == 7 || formContent[i].type == 8) {
                            submitData[formContent[i].desc] = new Date(formContent[i].onlyData)
                        } else if (formContent[i].type == 9) {

                            let pickerValue1 = formContent[i].onlyData ? JSON.parse(formContent[i].onlyData).addressCode : ""
                            let detailMsg1 = formContent[i].onlyData ? JSON.parse(formContent[i].onlyData).addressDetail : ""
                            this.setState({
                                ['pickerValue' + (formContent[i].desc)]: pickerValue1,
                                ['detailMsg' + (formContent[i].desc)]: detailMsg1,
                            })

                            if (formFields.formFields.indexOf("address") >= 0 && formContent[i].desc == "地址") {
                                let pickerValue = formContent[i].onlyData ? JSON.parse(formContent[i].onlyData).addressCode : ""
                                let detailMsg = formContent[i].onlyData ? JSON.parse(formContent[i].onlyData).addressDetail : ""
                                this.setState({
                                    pickerValue: pickerValue,
                                    detailMsg: detailMsg,
                                    readFlag: true
                                })
                            }


                        } else {
                            submitData[formContent[i].desc] = formContent[i].value
                        }


                    }
                    if (data.phoneno) {
                        this.setState({
                            phone: data.phoneno,
                            readFlag: true
                        })
                    }
                    if (data.realname) {
                        this.setState({
                            name: data.realname,
                            readFlag: true
                        })
                    }
                    if (data.remark) {
                        this.setState({
                            remarkData: data.remark,
                            readFlag: true
                        })
                    }
                    this.setState({
                        submitData: submitData,
                        formFields: formFields,
                        readFlag: true
                    })

                }
            }, () => {
                Toast.info("订单详情系统错误！", 3, null, false)
            })
        }
        this.setState({
            tradeno: tradeno
        })
        Api.mdistriPreorder((data) => {
            if (data.respCode == 0) {
                let preorderData = data.respMsg
                if (preorderData.formFields) {
                    if (this.isJsonString(preorderData.formFields)) {
                        preorderData.formFields = JSON.parse(preorderData.formFields);
                    } else {
                        preorderData.formFields = preorderData.formFields;
                    }
                }
                this.setState({
                    preorderData: preorderData,
                    formFields: preorderData.formFields
                })
            }
        })

        Api.mdistriPartnum((res) => {
            // alert(JSON.stringify(res))
            if (res.respCode == 0) {
                this.setState({
                    partNum: res.respMsg.partNum
                })
            }
        }, () => {
            Toast.info("获取参与人数系统错误！", 3, null, false)
        })
    }

    parseParmStr(parmStr) {
        if (parmStr.indexOf("?") != -1) {
            var index = parmStr.indexOf('?');
            var parms = {};
            parmStr = parmStr.substr(index + 1);
            let strs = parmStr.split('&'); //["userName=mona", "pwd=3"]

            for (var i = 0; i < strs.length; i++) {
                parms[strs[i].split('=')[0]] = strs[i].split('=')[1];
            }
        }


        return parms;
    }

    hideModal(key) {
        this.setState({
            [key]: false
        })
    }

    showModal(key) {
        this.setState({
            [key]: true
        })
    }

    getName(data) {
        this.setState({
            name: data
        })
    }

    getPhone(data) {
        this.setState({
            phone: data
        })

    }

    getRemark(data) {
        this.setState({
            remarkData: data
        })
    }

    getAddress(e) {
        this.setState({
            pickerValue: e,
            addressId: ''
        })
    }

    getAddressNew(key, e) {
        this.setState({
            [key]: e,
            addressId: ''
        })
    }

    getDetailMsg(data) {
        this.setState({
            detailMsg: data
        })
    }

    getDetailMsgNew(key, data) {
        this.setState({
            [key]: data
        })
    }

    payMust(tradeno) {
        let phoneno = this.state.phone,
            realname = this.state.name,
            remark = this.state.remarkData,
            address = {},
            addressCodeStr = document.getElementsByClassName("am-list-extra")[1] ? document.getElementsByClassName("am-list-extra")[1].innerText : "",
            params = {}, tradenoNew = null, formContent = [], formData = [];


        let formFields = [], customerFields = [], submitData = this.state.submitData;
        let needForm = this.state.preorderData.needForm;
        if (needForm == 1) {
            if (this.state.preorderData && this.state.preorderData.formFields) {
                formFields = this.state.preorderData.formFields.formFields;
                customerFields = this.state.preorderData.formFields.customerFields;
            }

            if (formFields.indexOf('realname') != -1) {
                if (realname.length <= 0) {
                    Toast.info("请输入姓名", 3, null, false)
                    return
                } else {
                    let obj = {
                        desc: "姓名",
                        value: realname,
                    }
                    formContent.push(obj);
                }
            }

            if (formFields.indexOf('phoneno') != -1) {
                if (phoneno.length <= 0) {
                    Toast.info("请输入手机号", 3, null, false)
                    return
                } else if (Util.regPhone(phoneno)) {
                    let obj = {
                        desc: "手机号",
                        value: phoneno,
                    }
                    formContent.push(obj);
                } else {
                    Toast.info("手机号错误，请重新输入", 3, null, false)
                    return
                }
            }

            if (formFields.indexOf('address') != -1) {
                if (this.state.pickerValue.length <= 0) {
                    Toast.info("请选择地区", 3, null, false)
                    return
                } else if (this.state.detailMsg.length <= 0) {
                    Toast.info("请输入详细地址", 3, null, false)
                    return
                } else {
                    address["addressCode"] = this.state.pickerValue;
                    let addZh = Util.getAntdDistrictZh(this.state.pickerValue, antdDistrict);
                    address["addressDetail"] = this.state.detailMsg;
                    address["addressStr"] = addZh + ' ' + this.state.detailMsg;
                    let obj = {
                        desc: "地址",
                        value: address["addressStr"],
                    }
                    formContent.push(obj);
                }
            }
            if (formFields.indexOf('remark') != -1) {
                let obj = {
                    desc: "备注",
                    value: remark,
                }
                formContent.push(obj);
            }
            let dataArr = this.formFieldsCmpt.getData();
            if (Util.isEmpty(dataArr)) {
                return
            }
            formData = formContent.concat(dataArr);
            // for (let i = 0; i < customerFields.length; i++) {
            //     let item = customerFields[i]
            //     if (item.type == 1) {
            //         if (!submitData[item.desc] || submitData[item.desc].length <= 0) {
            //             Toast.info("请输入手机号", 3, null, false)
            //             return;
            //         } else if (Util.regPhone(submitData[item.desc])) {
            //             let obj = {
            //                 desc: item.desc,
            //                 value: submitData[item.desc]
            //             }
            //             formContent.push(obj);
            //         } else {
            //             Toast.info("手机号错误，请重新输入", 3, null, false)
            //             return
            //         }
            //     }
            //     if (item.type == 3 || item.type == 7 || item.type == 8) {
            //         if (!submitData[item.desc]) {
            //             Toast.info("请输入" + item.desc, 3, null, false)
            //             return
            //         } else {
            //             let val = ""
            //             if (item.type == 3) {
            //                 val = new Date(submitData[item.desc]).format('yyyy-MM-dd')
            //             }
            //             if (item.type == 7) {
            //                 val = new Date(submitData[item.desc]).getHours() + ":" + new Date(submitData[item.desc]).getMinutes()
            //             }
            //             if (item.type == 8) {
            //                 val = new Date(submitData[item.desc]).format('yyyy-MM-dd hh:mm')
            //             }
            //             let obj = {
            //                 desc: item.desc,
            //                 value: val,
            //                 type: item.type,
            //                 onlyData: submitData[item.desc]
            //             }
            //             formContent.push(obj);
            //         }
            //     }
            //     if (item.type == 5) {
            //         if (!submitData[item.desc] || submitData[item.desc].length <= 0) {
            //             Toast.info("请输入" + item.desc, 3, null, false)
            //             return
            //         } else if (Util.regIdCard(submitData[item.desc])) {
            //             let obj = {
            //                 desc: item.desc,
            //                 value: submitData[item.desc]
            //             }
            //             formContent.push(obj);
            //         } else {
            //             Toast.info(item.desc + "错误，请重新输入", 3, null, false)
            //             return
            //         }
            //     }
            //     if (item.type == 6) {
            //         if (!submitData[item.desc] || submitData[item.desc].length <= 0) {
            //             Toast.info("请输入" + item.desc, 3, null, false)
            //             return
            //         } else if (Util.regEmail(submitData[item.desc])) {
            //             let obj = {
            //                 desc: item.desc,
            //                 value: submitData[item.desc]
            //             }
            //             formContent.push(obj);
            //         } else {
            //             Toast.info(item.desc + "错误，请重新输入", 3, null, false)
            //             return
            //         }
            //     }
            //     if (item.type == 9) {
            //         if (!this.state['pickerValue' + (item.desc)] || this.state['pickerValue' + (item.desc)].length <= 0) {
            //             Toast.info("请选择地区", 3, null, false)
            //             return
            //         } else if (this.state['detailMsg' + (item.desc)].length <= 0) {
            //             Toast.info("请输入详细地址", 3, null, false)
            //             return
            //         } else {
            //             address["addressCode"] = this.state['pickerValue' + (item.desc)];
            //             let addZh = Util.getAntdDistrictZh(this.state['pickerValue' + (item.desc)], antdDistrict);
            //             address["addressDetail"] = this.state['detailMsg' + (item.desc)];
            //             address["addressStr"] = addZh + ' ' + this.state['detailMsg' + (item.desc)];
            //             let obj = {
            //                 desc: item.desc,
            //                 value: address["addressStr"],
            //                 type: item.type,
            //                 onlyData: JSON.stringify(address)
            //             }
            //             formContent.push(obj);
            //         }
            //     }
            //
            //     if (item.type == 2 || item.type == 4) {
            //         if (!submitData[item.desc] || submitData[item.desc].length <= 0) {
            //             Toast.info("请输入" + item.desc, 3, null, false)
            //             return
            //         } else {
            //             let obj = {
            //                 desc: item.desc,
            //                 value: submitData[item.desc],
            //                 type: item.type,
            //             }
            //             formContent.push(obj);
            //         }
            //     }
            // }

        }

        // params["groupId"] = this.groupId ? this.groupId : 0;
        // params["isGroupbuy"] = this.isGroupbuy;
        params["payType"] = 1;
        params["formContent"] = formData;

        if (!this.payFlag) {
            this.payFlag = true
            if (Number(tradeno) > 0) {
                if (tradeno == undefined) {
                    Toast.info("订单编号不正确", 3, null, false)
                    return
                }
                this.onBridgeReady(tradeno)
                // this.onBridgeReady(tradeno)
            } else {
                Api.mdistriUnifiedorder(params, (res) => {
                    if (res.respCode == 0) {
                        tradenoNew = res.respMsg.tradeno
                        this.setState({
                            tradeno: tradenoNew
                        })
                        if (tradenoNew == undefined) {
                            Toast.info("生成订单编号不正确", 3, null, false)
                            return
                        }
                        if (res.respMsg.wxJSPayConfig) {
                            this.getBridgeReady(res.respMsg.wxJSPayConfig, tradenoNew)
                        } else {
                            if (typeof WeixinJSBridge == "undefined") {
                                if (document.addEventListener) {
                                    document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(tradenoNew), false);
                                } else if (document.attachEvent) {
                                    document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(tradenoNew));
                                    document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(tradenoNew));
                                }
                            } else {
                                this.onBridgeReady(tradenoNew);
                            }
                        }
                    } else if (res.respCode == 2001) {
                        this.payFlag = false
                        Modal.alert('提示', Util.checkCode[res.respCode], [
                            {
                                text: '取消',
                                onPress: () => console.log('cancel'),
                                style: 'cancel',
                            }
                        ]);
                    } else {
                        // this.setState({
                        //     payFlag:false
                        // })
                        this.payFlag = false
                        Toast.info("生成订单系统错误", 3, null, false)
                    }
                }, () => {
                    // this.setState({
                    //     payFlag:false
                    // })
                    this.payFlag = false
                    Toast.info("生成订单系统错误", 3, null, false)
                })
            }
        }


    }

    getBridgeReady(msg, tradeno) {
        if (tradeno == undefined) {
            Toast.info("微信支付订单编号不正确", 3, null, false)
            return
        }
        let that = this
        this.payFlag = false
        // alert(JSON.stringify(data.respMsg))
        // alert("msg="+JSON.stringify(msg))
        // if(typeof WeixinJSBridge != "undefined"){
        window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest', msg,
            function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    that.getPaySuccess(tradeno)
                    Toast.loading("支付完成，跳转中...", 1000, null, true);
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                    Toast.info("支付失败", 3, null, false)
                    that.setState({
                        noPayFlag: true
                    })
                    this.payFlag = false
                } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                    that.setState({
                        noPayFlag: true
                    })
                    // alert('房地产年度')
                    this.payFlag = false
                }
            });
        // }
    }

    onBridgeReady(tradeno) {
        this.payFlag = false
        if (tradeno == undefined) {
            Toast.info("wxpay订单编号不正确", 3, null, false)
            return
        }
        Api.getWXPay(tradeno, (data) => {
            // alert(JSON.stringify(data))
            if (data.respCode == 0) {
                let msg = data.respMsg
                let that = this
                if (tradeno == undefined) {
                    Toast.info("wxpay微信支付订单编号不正确", 3, null, false)
                    return
                }
                // alert(JSON.stringify(data.respMsg))
                // alert("msg="+JSON.stringify(msg))
                // if(typeof WeixinJSBridge != "undefined"){
                window.WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', msg,
                    function (res) {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            that.getPaySuccess(tradeno)
                            Toast.loading("支付完成，跳转中...", 1000, null, true);
                            // 使用以上方式判断前端返回,微信团队郑重提示：
                            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                            Toast.info("支付失败", 3, null, false)
                            that.setState({
                                noPayFlag: true
                            })
                        } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                            // this.setState({
                            //     payFlag:false
                            // })
                            that.setState({
                                noPayFlag: true
                            })
                            this.payFlag = false
                        }
                    });
                // }

            }
        }, (error) => {
            Toast.info("支付系统错误", 3, null, false)
        })

    }

    getPaySuccess(tradeno) {
        if (tradeno == undefined) {
            Toast.info("轮询结果订单编号不正确", 3, null, false)
            return
        }
        setTimeout(() => {
            Api.getWXPayResult(tradeno, (data) => {
                // alert(JSON.stringify(data))
                if (data.respCode == 0) {
                    // clearInterval(paySuccessTime)
                    // alert(data.respCode)
                    Api.getPaySuccess((data) => {
                        // alert(JSON.stringify(data))
                        if (data.respCode == 0) {
                            let msg = data.respMsg
                            if (msg.type == 3) {
                                window.location.href = msg.content
                            } else if (msg.type == 1 || msg.type == 2 || msg.type == 5) {

                                let urlHref = window.sessionStorage.getItem("urlHref")
                                window.sessionStorage.setItem("urlHrefFlag", true)
                                Toast.hide()
                                window.location.href = urlHref + Path.getSuccessLink() + "?tradeno=" + tradeno
                                // let urlHref = window.sessionStorage.getItem("urlHref")
                                // window.history.replaceState(null,'',urlHref+Path.getSuccessLink()+"?tradeno="+tradeno)
                                // this.props.history.replace(Path.getSuccessLink(),{tradeno:tradeno})
                            }
                        }
                    }, (err) => {
                        // alert(err)
                        Toast.info("支付跳转系统错误", 3, null, false)
                    })

                } else {
                    this.getPaySuccess(tradeno)
                }
            }, () => {
                this.getPaySuccess(tradeno)
                Toast.info("支付成功系统错误", 3, null, false)
            })
        }, 500)

    }

    getVal(key, val) {
        this.setState({
            [key]: val
        })
    }

    changeInput(submitData, key, e) {
        submitData[key] = e
        this.getVal("submitData", submitData)
    }

    changeDate(submitData, key, e) {
        console.log(e)
        submitData[key] = e
        this.getVal("submitData", submitData)
    }

    onConfirm() {

    }

    render() {
        let districtData = require('../public/location');
        Object.keys(districtData).forEach((index) => {
            let itemLevel1 = {};
            let itemLevel2 = {};
            itemLevel1.value = districtData[index].code;
            itemLevel1.label = districtData[index].name;
            itemLevel1.children = [];
            let data = districtData[index].cities;
            Object.keys(data).forEach((index) => {
                itemLevel2.value = data[index].code;
                itemLevel2.label = data[index].name;
                itemLevel2.children = [];
                let data2 = data[index].districts;
                let itemLevel3 = {};
                itemLevel3.children = [];
                Object.keys(data2).forEach((index) => {
                    itemLevel3.value = index;
                    itemLevel3.label = data2[index];
                    itemLevel2.children.push(itemLevel3);
                    itemLevel3 = {};
                });
                itemLevel1.children.push(itemLevel2);
                itemLevel2 = {};
            });
            antdDistrict.push(itemLevel1)
        });
        let preorderData = this.state.preorderData;
        return (
            <div>
                {
                    preorderData ? <div className={'distriOpenPay'}>
                        <ChangeTitle title={preorderData.name}></ChangeTitle>
                        <List className={"openList " + (this.state.readFlag ? "disabled" : '')}>
                            <div className={'activityInfo'}>
                                    <span className={'bgImg'}
                                          style={{backgroundImage: "url(" + preorderData.coverImgUrl + ")"}}></span>
                                <div className={'con'}>
                                    <p className={'name'}>
                                        {/*{*/}
                                        {/*preorderData.limited==true ? <span>分销</span> : ""*/}
                                        {/*}*/}

                                        {preorderData.name}
                                    </p>
                                    <p className={'price'}>{'¥' + parseFloat((preorderData.limited == 1 ? preorderData.bargainPrice : preorderData.price) / 100).toFixed(2)}</p>
                                    {
                                        preorderData.opSwitch == 1 ?
                                            <del>{'原价：¥' + parseFloat((preorderData.price) / 100).toFixed(2)}</del> : ""
                                    }


                                </div>
                            </div>
                            {/*{*/}
                            {/*this.groupId ? <Item*/}
                            {/*extra={(preorderData.gnum) + '人'}>拼团人数</Item> : ""*/}
                            {/*}*/}
                            <Item
                                extra={'¥' + parseFloat((preorderData.limited == 1 ? preorderData.bargainPrice : preorderData.price) / 100).toFixed(2)}>实付金额</Item>
                            {
                                preorderData.needForm == 1 ?
                                    <div>
                                        {
                                            preorderData.formFields.formFields && preorderData.formFields.formFields.length > 0 ?
                                                <div>

                                                    {
                                                        preorderData.formFields.formFields.indexOf("realname") >= 0 ?
                                                            <InputItem
                                                                placeholder="输入真实姓名"
                                                                clear
                                                                type="text"
                                                                value={this.state.name}
                                                                onChange={this.getName}
                                                                onVirtualKeyboardConfirm={this.getName}
                                                                editable={!this.state.readFlag}
                                                            >姓名</InputItem> : ""
                                                    }
                                                    {
                                                        preorderData.formFields.formFields.indexOf("phoneno") >= 0 ?
                                                            <InputItem
                                                                pattern="[0-9]*"
                                                                clear
                                                                maxLength={11}
                                                                placeholder="输入手机号"
                                                                type="number"
                                                                value={this.state.phone}
                                                                onChange={this.getPhone}
                                                                onVirtualKeyboardConfirm={this.getPhone}
                                                                editable={!this.state.readFlag}
                                                            >手机号</InputItem> : ""
                                                    }
                                                    {
                                                        preorderData.formFields.formFields.indexOf("address") >= 0 ?
                                                            <div className={'chooseArea'}>
                                                                <Picker extra={<span
                                                                    className="addressSelectExtra">地区信息</span>}
                                                                        data={antdDistrict}
                                                                        value={this.state.pickerValue}
                                                                        title=""
                                                                        onOk={this.getAddress}
                                                                        onDismiss={e => console.log('dismiss', e)}
                                                                        disabled={this.state.readFlag}
                                                                >
                                                                    <List.Item
                                                                        arrow="horizontal">所在区域</List.Item>
                                                                </Picker>
                                                                <InputItem
                                                                    placeholder="街道门牌信息"
                                                                    type="text"
                                                                    value={this.state.detailMsg}
                                                                    onChange={this.getDetailMsg}
                                                                    onVirtualKeyboardConfirm={this.getDetailMsg}
                                                                    editable={!this.state.readFlag}
                                                                >详细地址</InputItem>
                                                            </div> : ""
                                                    }
                                                    {
                                                        preorderData.formFields.formFields.indexOf("remark") >= 0 ?
                                                            <div>
                                                                {/*<div className={'mxd-body-trigger'}></div>*/}
                                                                <TextareaItem
                                                                    rows={3}
                                                                    title={'备注'}
                                                                    placeholder="请输入备注信息"
                                                                    value={this.state.remarkData}
                                                                    onChange={this.getRemark}
                                                                    onVirtualKeyboardConfirm={this.getRemark}
                                                                    editable={!this.state.readFlag}
                                                                />
                                                            </div>
                                                            : ""
                                                    }
                                                </div> : ""
                                        }
                                        {
                                            this.state.formFields.customerFields && this.state.formFields.customerFields.length > 0 ?
                                                <div>
                                                    <FormFields fields={this.state.formFields.customerFields}
                                                                ref={r => this.formFieldsCmpt = r}/>
                                                    {/*{*/}
                                                    {/*preorderData.formFields.customerFields.map((item, index) => {*/}

                                                    {/*if (item.type == 2 || item.type == 5 || item.type == 6) {*/}
                                                    {/*return (*/}
                                                    {/*<InputItem*/}
                                                    {/*placeholder={"输入" + (item.desc)}*/}
                                                    {/*clear*/}
                                                    {/*type="text"*/}
                                                    {/*value={this.state.submitData[item.desc]}*/}
                                                    {/*onChange={this.changeInput.bind(this, this.state.submitData, item.desc)}*/}
                                                    {/*onVirtualKeyboardConfirm={this.changeInput.bind(this, this.state.submitData, item.desc)}*/}
                                                    {/*editable={!this.state.readFlag}*/}
                                                    {/*>{item.desc}</InputItem>*/}
                                                    {/*)*/}
                                                    {/*}*/}

                                                    {/*if (item.type == 1) {*/}
                                                    {/*return (*/}
                                                    {/*<InputItem*/}
                                                    {/*pattern="[0-9]*"*/}
                                                    {/*clear*/}
                                                    {/*maxLength={11}*/}
                                                    {/*placeholder={"输入" + (item.desc)}*/}
                                                    {/*type="number"*/}
                                                    {/*value={this.state.submitData[item.desc]}*/}
                                                    {/*onChange={this.changeInput.bind(this, this.state.submitData, item.desc)}*/}
                                                    {/*onVirtualKeyboardConfirm={this.changeInput.bind(this, this.state.submitData, item.desc)}*/}
                                                    {/*editable={!this.state.readFlag}*/}
                                                    {/*>{item.desc}</InputItem>*/}
                                                    {/*)*/}
                                                    {/*}*/}

                                                    {/*if (item.type == 3) {*/}
                                                    {/*return (*/}
                                                    {/*<div className={'dateTime'}>*/}
                                                    {/*<DatePicker*/}
                                                    {/*mode="date"*/}
                                                    {/*title={"请选择" + (item.desc)}*/}
                                                    {/*extra={"请选择" + (item.desc)}*/}
                                                    {/*value={this.state.submitData[item.desc]}*/}
                                                    {/*onChange={this.changeDate.bind(this, this.state.submitData, item.desc)}*/}
                                                    {/*disabled={this.state.readFlag}*/}
                                                    {/*>*/}
                                                    {/*<List.Item*/}
                                                    {/*arrow="horizontal">{item.desc}</List.Item>*/}
                                                    {/*</DatePicker>*/}
                                                    {/*</div>*/}
                                                    {/*)*/}
                                                    {/*}*/}

                                                    {/*if (item.type == 4) {*/}
                                                    {/*return (*/}
                                                    {/*<InputItem*/}
                                                    {/*clear*/}
                                                    {/*placeholder={"请输入" + (item.desc)}*/}
                                                    {/*type="number"*/}
                                                    {/*value={this.state.submitData[item.desc]}*/}
                                                    {/*onChange={this.changeInput.bind(this, this.state.submitData, item.desc)}*/}
                                                    {/*onVirtualKeyboardConfirm={this.changeInput.bind(this, this.state.submitData, item.desc)}*/}
                                                    {/*disabled={this.state.readFlag}*/}
                                                    {/*>{item.desc}</InputItem>*/}
                                                    {/*)*/}
                                                    {/*}*/}

                                                    {/*if (item.type == 7) {*/}
                                                    {/*return (*/}
                                                    {/*<div className={'dateTime'}>*/}
                                                    {/*<DatePicker*/}
                                                    {/*mode="time"*/}
                                                    {/*minuteStep={2}*/}
                                                    {/*use24Hours*/}
                                                    {/*value={this.state.submitData[item.desc]}*/}
                                                    {/*onChange={this.changeDate.bind(this, this.state.submitData, item.desc)}*/}
                                                    {/*>*/}
                                                    {/*<List.Item*/}
                                                    {/*arrow="horizontal">{item.desc}</List.Item>*/}
                                                    {/*</DatePicker>*/}
                                                    {/*</div>*/}
                                                    {/*)*/}
                                                    {/*}*/}

                                                    {/*if (item.type == 8) {*/}
                                                    {/*return (*/}
                                                    {/*<div className={'dateTime'}>*/}
                                                    {/*<DatePicker*/}
                                                    {/*value={this.state.submitData[item.desc]}*/}
                                                    {/*onChange={this.changeDate.bind(this, this.state.submitData, item.desc)}*/}
                                                    {/*>*/}
                                                    {/*<List.Item*/}
                                                    {/*arrow="horizontal">{item.desc}</List.Item>*/}
                                                    {/*</DatePicker>*/}
                                                    {/*</div>*/}
                                                    {/*)*/}
                                                    {/*}*/}

                                                    {/*if (item.type == 9) {*/}
                                                    {/*return (*/}
                                                    {/*<div className={'chooseArea'}>*/}
                                                    {/*<Picker extra={<span*/}
                                                    {/*className="addressSelectExtra">请选择地区</span>}*/}
                                                    {/*data={antdDistrict}*/}
                                                    {/*value={this.state['pickerValue' + (item.desc)]}*/}
                                                    {/*title=""*/}
                                                    {/*onOk={this.getAddressNew.bind(this, "pickerValue" + (item.desc))}*/}
                                                    {/*onDismiss={e => console.log('dismiss', e)}*/}
                                                    {/*editable={!this.state.readFlag}*/}
                                                    {/*>*/}
                                                    {/*<List.Item*/}
                                                    {/*arrow="horizontal">所在区域</List.Item>*/}
                                                    {/*</Picker>*/}
                                                    {/*<InputItem*/}
                                                    {/*placeholder="街道门牌信息"*/}
                                                    {/*type="text"*/}
                                                    {/*value={this.state['detailMsg' + (item.desc)]}*/}
                                                    {/*onChange={this.getDetailMsgNew.bind(this, 'detailMsg' + (item.desc))}*/}
                                                    {/*onVirtualKeyboardConfirm={this.getDetailMsgNew.bind(this, 'detailMsg' + (item.desc))}*/}
                                                    {/*>详细地址</InputItem>*/}
                                                    {/*</div>*/}
                                                    {/*)*/}
                                                    {/*}*/}

                                                    {/*})*/}
                                                    {/*}*/}
                                                </div> : ""
                                        }
                                    </div> : ""
                            }


                        </List>

                        <p className={'text-tip-gray'}><span className={'icon-tip-gray'}></span>所填信息不公开，仅用于活动核销。
                        </p>
                        <ul className={'payItems clearfix'}>
                            <li className={'payItem'}><span className={'icon-wxpay'}></span>微信支付
                                <span className={'icon-radio right'}></span>
                            </li>
                        </ul>

                        <div className={'fixedBot'} style={{
                            position: 'fixed',
                            width: '100%',
                            bottom: 0,
                            left: 0
                        }}>
                            <div className={'fixedBtn'}>
                                <div className={'bottomBtns'}>
                                    <div>实付金额：<span
                                        className={'text-danger'}>{'¥' + parseFloat((this.state.preorderData.limited ? this.state.preorderData.bargainPrice : this.state.preorderData.price) / 100).toFixed(2)}</span>
                                    </div>
                                    <div className={'danger flex1'}
                                         onClick={this.payMust.bind(this, this.state.tradeno)}>立即支付
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className={'distriTech'}>
                            <Technology></Technology>
                        </span>
                    </div> : ""
                }
                <div>
                    {
                        this.state.noPayFlag && this.state.preorderData ?
                            <NoPay props={this.props} goodsData={this.state.preorderData}
                                   partNum={this.state.partNum}
                                   tradeno={this.state.tradeno}
                                   nextPay={this.payMust.bind(this, this.state.tradeno)}
                                   closePay={() => {
                                       this.setState({noPayFlag: false})
                                   }}></NoPay> : ""
                    }
                </div>
            </div>
        )
    }
}

export default OpenPay