import React, {Component} from 'react'
import {Button, Tabs, Modal, Toast, List} from "antd-mobile"
import {Path} from "../../../path";
import Util from "../../../util/util";

const Item = List.Item;

class NoPay extends Component {

    constructor(props) {
        super(props);
        this.gotoPayNext = this.gotoPayNext.bind(this)
        this.gotoPayNewNext = this.gotoPayNewNext.bind(this)
        this.noPayClose = this.noPayClose.bind(this)
        this.state = {
            noPayFlag: false
        }
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    noPayClose() {
        this.setState({
            noPayFlag: true
        })
        this.props.closePay()
    }

    gotoPayNext(tradeno) {
        this.props.props.history.push(Path.getOpenPay(),{tradeno:tradeno})
    }

    gotoPayNewNext(){
        this.props.nextPay()
    }

    render() {
        return (
            <div className={'noPayWrapper'} onClick={this.noPayClose}>
                <div className={'noPayCon'}>
                    <div className={'noPayBg'}>
                        <p className={'title'}>您未完成支付</p>
                        <p className={'desc descNum'}>{'已有' +(this.props.partNum?this.props.partNum:this.props.goodsData.partNum)  + '人'}</p>
                        <p className={'desc'}>与你一起参加了活动</p>
                        {
                            this.props.flag == "index" ?
                                <div className={'payBtn'}
                                     onClick={this.gotoPayNext.bind(this,this.props.goodsData.latestTradeno)}>继续支付
                                </div> : <div className={'payBtn'}
                                              onClick={this.gotoPayNewNext.bind(this,this.props.goodsData.latestTradeno)}>继续支付
                                </div>
                        }
                        {/*<div className={'payBtn'} onClick={this.gotoPayNext.bind(this,this.props.goodsData.latestTradeno)}>继续支付</div>*/}
                        <div className={'payBtnNo'} onClick={this.noPayClose}>忍痛放弃</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NoPay